import { connect } from "react-redux";

import Modal from "~/components/common/modal";
import { Button } from "@bit/healthmug.admin.button";

import { OrdersModalEditMrp } from "~/actions/orders-action";

const EditMrpModal = (props) => {
	return (
		<Modal micro closeButtonOutside bgClose={false} escClose={false} id="modalMrpEdit">
			<div className="modalBody uk-text-center">
				<div className="uk-padding-small">
					<div className="mediumFont uk-margin-small-bottom">Are you sure you want to edit the MRP?</div>

					<Button
						onClick={() => props.Orders_Modal_Edit_Mrp(true)}
						primary
						small
						className="uk-width-1-1 uk-margin-small-top uk-margin-small-bottom"
					>
						Yes
					</Button>
					<Button
						onClick={() => props.Orders_Modal_Edit_Mrp(false)}
						small
						className="uk-modal-close uk-width-1-1 uk-margin-small-top uk-margin-small-bottom"
					>
						Cancel
					</Button>
				</div>
			</div>
		</Modal>
	);
};

const mapDispatchToProps = (dispatch) => ({
	Orders_Modal_Edit_Mrp: (mrp_update_confirmation) => dispatch(OrdersModalEditMrp(mrp_update_confirmation))
});
export default connect(null, mapDispatchToProps)(EditMrpModal);
