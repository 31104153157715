import { useState } from "react";
import { connect } from "react-redux";

import Modal from "~/components/common/modal";
import { Button } from "@bit/healthmug.admin.button";
import { Select } from "@bit/healthmug.admin.select";

import { useLoader } from "~/actions/custom-hooks";
import { OrdersCancelItems } from "~/actions/orders-action";

const CancelModal = (props) => {
	const [button_loader, start_button_loader, end_button_loader] = useLoader(false);
	const [cancel_reason, set_cancel_reason] = useState("select");

	const Orders_Cancel_Items = () => {
		start_button_loader();
		props.Orders_Cancel_Items(cancel_reason).finally(end_button_loader);
	};

	return (
		<Modal micro closeButtonOutside onCloseButtonClick={() => set_cancel_reason("select")} bgClose={false} escClose={false} id="modalCancel">
			<div className="modalBody">
				<div className="mediumFont uk-margin-small-top uk-margin-small-bottom">Do you really want to cancel the selected order items?</div>
				<Select value={cancel_reason} onChange={e => set_cancel_reason(e.target.value)} small>
					<option value="select">Select Reason</option>
					{props.cancel_reasons.map(function (cancel_reason, i) {
						return (
							<option key={i} value={cancel_reason.id}>
								{cancel_reason.reason}
							</option>
						);
					})}
				</Select>
			</div>
			<div className="modalFooter bordered uk-text-right uk-margin-small-bottom">
				<Button onClick={() => set_cancel_reason("select")} className="uk-modal-close" small style={{ minWidth: "120px" }}>
					Close
				</Button>
				<Button
					onClick={Orders_Cancel_Items}
					loader={button_loader}
					small
					className="uk-margin-small-left"
					danger
					style={{ minWidth: "120px" }}
				>
					Yes
				</Button>
			</div>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	cancel_reasons: state.orders_store.datalist.cancel_reasons
});
const mapDispatchToProps = (dispatch) => ({
	Orders_Cancel_Items: (cancel_reason) => dispatch(OrdersCancelItems(cancel_reason))
});
export default connect(mapStateToProps, mapDispatchToProps)(CancelModal);