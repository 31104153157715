import React from "react";

export const Grid = ({ children, striped, className, bordered, equal, responsive }) => {
	let extraClass = "gridLayout";
	if (striped) {
		extraClass += " strip";
	}

	if (className) {
		extraClass += ` ${className}`;
	}
	if (bordered) {
		extraClass += " border";
	}
	if (equal) {
		extraClass += " flexGrid";
	}
	if (responsive) {
		extraClass += " responsive";
	}
	return <div className={extraClass}>{children}</div>;
};

export const GridHead = ({ children, className }) => {
	let classList = "gridLayoutRowHead";
	if (className) {
		classList += ` ${className}`;
	}
	return (
		<div className={classList}>
			<div className="uk-grid uk-grid-column-small">{children}</div>
		</div>
	);
};

export const GridBody = ({ children }) => {
	return (
		<div className="gridLayoutRow">
			<div className="uk-grid uk-grid-column-small">{children}</div>
		</div>
	);
};

export const Col = ({ children, className }) => {
	return <div className={className}>{children} </div>;
};
