import { batch } from "react-redux";

import {
	PERFORMANCE_RELOAD_DATA,
	DASHBOARD_GET_INITIAL_DATA,
	PERFORMANCE_GET_INITIAL_DATA,
	PERFORMANCE_UPDATE_GRAPH,
	PERFORMANCE_CLEAR_LOCAL_STATE
} from "~/actions/action-types";

import { ProjectUrl } from "~/env";

//Performance
export const PerformanceGetInitialData = () => (dispatch) => {
	dispatch({ type: PERFORMANCE_CLEAR_LOCAL_STATE });
	return fetch(ProjectUrl + "/analytics/getperformancedata", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: PERFORMANCE_GET_INITIAL_DATA, data: data });
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};
const PerformanceGetData = (period, selected_seller) => (dispatch) => {
	nanobar.go(50);
	fetch(ProjectUrl + "/analytics/getperformance", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ period: period, seller: selected_seller })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			nanobar.go(100);
			dispatch({ type: PERFORMANCE_RELOAD_DATA, data: data, period: period, selected_seller: selected_seller });
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const PerformanceOnChangeSeller = (value) => (dispatch, getState) => {
	const period = getState().performance_store.period;
	dispatch(PerformanceGetData(period, value));
};

export const GetPerformanceData = (period) => (dispatch, getState) => {
	const selected_seller = getState().performance_store.selected_seller;
	dispatch(PerformanceGetData(period, selected_seller));
};

export const CalculateSellerPerformance = () => (dispatch, getState) => {
	let seller_performance = { medal: "bronze", rating: 0 };
	let seller_rating = Math.round(getState().performance_store.datalist.data.seller_rating) / 10;
	let seller_class = getState().performance_store.datalist.data.seller_class;

	if (seller_class == 3) {
		seller_performance.medal = "gold";
	} else if (seller_class == 2) {
		seller_performance.medal = "silver";
	} else if (seller_class == 1) {
		seller_performance.medal = "bronze";
	}
	seller_performance.rating = seller_rating;

	return seller_performance;
};

export const PerformanceServiceCalculateQuality = (type) => (dispatch, getState) => {
	const cancelled_ratio = getState().performance_store.datalist.data.cancelled_ratio;
	const dispatch_breaches_ratio = getState().performance_store.datalist.data.dispatch_breaches_ratio;
	const fast_fulfilment_ratio = getState().performance_store.datalist.data.fast_fulfilment_ratio;
	var quality = {};

	if (type == "cancellation") {
		if (cancelled_ratio < 0.25) {
			quality = { medal: "gold", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "0.25%" };
		} else if (cancelled_ratio < 0.5) {
			quality = { medal: "silver", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "0.25%" };
		} else if (cancelled_ratio < 2) {
			quality = { medal: "bronze", rating: "qualityScore average", benchmark: "Silver Benchmark", benchmark_rating: "0.50%" };
		} else {
			quality = { medal: "bronze", rating: "qualityScore poor", benchmark: "Bronze Benchmark", benchmark_rating: "2.00%" };
		}
	} else if (type == "breached") {
		if (dispatch_breaches_ratio < 0.5) {
			quality = { medal: "gold", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "0.50%" };
		} else if (dispatch_breaches_ratio < 1) {
			quality = { medal: "silver", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "0.50%" };
		} else if (dispatch_breaches_ratio < 3) {
			quality = { medal: "bronze", rating: "qualityScore average", benchmark: "Silver Benchmark", benchmark_rating: "1.00%" };
		} else {
			quality = { medal: "bronze", rating: "qualityScore poor", benchmark: "Bronze Benchmark", benchmark_rating: "3.00%" };
		}
	} else if (type == "fast_fulfilment") {
		if (fast_fulfilment_ratio > 50) {
			quality = { medal: "gold", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "50%" };
		} else if (fast_fulfilment_ratio > 25) {
			quality = { medal: "silver", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "50%" };
		} else if (fast_fulfilment_ratio > 10) {
			quality = { medal: "bronze", rating: "qualityScore average", benchmark: "Silver Benchmark", benchmark_rating: "25%" };
		} else {
			quality = { medal: "bronze", rating: "qualityScore poor", benchmark: "Bronze Benchmark", benchmark_rating: "10%" };
		}
	}

	return quality;
};

export const PerformanceProductCalculateQuality = (type) => (dispatch, getState) => {
	const shipment_model = getState().performance_store.datalist.data.shipment_model;
	const customer_issues_ratio = getState().performance_store.datalist.data.customer_issues_ratio;
	const hub_issues_ratio = getState().performance_store.datalist.data.hub_issues_ratio;
	const stockout_perc = getState().performance_store.datalist.data.stockout_perc;
	var quality = {};

	if (type == "customer_issues" && shipment_model != 2) {
		if (customer_issues_ratio < 0.15) {
			quality = { medal: "gold", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "0.15%" };
		} else if (customer_issues_ratio < 0.25) {
			quality = { medal: "silver", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "0.15%" };
		} else if (customer_issues_ratio < 0.75) {
			quality = { medal: "bronze", rating: "qualityScore average", benchmark: "Silver Benchmark", benchmark_rating: "0.25%" };
		} else {
			quality = { medal: "bronze", rating: "qualityScore poor", benchmark: "Bronze Benchmark", benchmark_rating: "0.75%" };
		}
	} else if (type == "customer_issues" && shipment_model == 2) {
		if (customer_issues_ratio < 0.25) {
			quality = { medal: "gold", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "0.25%" };
		} else if (customer_issues_ratio < 0.5) {
			quality = { medal: "silver", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "0.25%" };
		} else if (customer_issues_ratio < 1.5) {
			quality = { medal: "bronze", rating: "qualityScore average", benchmark: "Silver Benchmark", benchmark_rating: "0.50%" };
		} else {
			quality = { medal: "bronze", rating: "qualityScore poor", benchmark: "Bronze Benchmark", benchmark_rating: "1.50%" };
		}
	} else if (type == "hub_issues") {
		if (hub_issues_ratio < 0.15) {
			quality = { medal: "gold", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "0.15%" };
		} else if (hub_issues_ratio < 0.25) {
			quality = { medal: "silver", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "0.15%" };
		} else if (hub_issues_ratio < 0.75) {
			quality = { medal: "bronze", rating: "qualityScore average", benchmark: "Silver Benchmark", benchmark_rating: "0.25%" };
		} else {
			quality = { medal: "bronze", rating: "qualityScore poor", benchmark: "Bronze Benchmark", benchmark_rating: "0.75%" };
		}
	} else if (type == "stockout") {
		if (stockout_perc < 5) {
			quality = { medal: "gold", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "5%" };
		} else if (stockout_perc < 10) {
			quality = { medal: "silver", rating: "qualityScore good", benchmark: "Gold Benchmark", benchmark_rating: "5%" };
		} else if (stockout_perc < 20) {
			quality = { medal: "bronze", rating: "qualityScore average", benchmark: "Silver Benchmark", benchmark_rating: "10%" };
		} else {
			quality = { medal: "bronze", rating: "qualityScore poor", benchmark: "Bronze Benchmark", benchmark_rating: "20%" };
		}
	}
	return quality;
};

export const PerformanceSaleChartData = () => (dispatch, getState) => {
	const Charts_sale = getState().performance_store.datalist.charts.sale;
	const sale_chart_period = getState().performance_store.sale_chart_period;
	var options = {
		series: {
			1: {
				type: "line",
				color: "#F7A35C",
				targetAxisIndex: 0,
				pointSize: 7
			}
		},
		colors: ["#95CEFF"],
		focusTarget: "category",
		bar: { groupWidth: "50%" },
		legend: { position: "none" },
		hAxis: {
			format: "dd MMM",
			gridlines: { count: -1 }
		},
		targetAxisIndex: 1
	};
	return {
		datalist: Charts_sale,
		options: options,
		period: sale_chart_period
	};
};

export const PerformanceReturnChartData = () => (dispatch, getState) => {
	const return_chart_period = getState().performance_store.return_chart_period;
	const Chart_Return = getState().performance_store.datalist.charts.returns;
	var options = {
		series: {
			1: {
				type: "line",
				color: "#387D7A",
				targetAxisIndex: 0,
				pointSize: 7
			}
		},
		colors: ["#2FBF71"],
		focusTarget: "category",
		bar: { groupWidth: "50%" },
		legend: { position: "none" },
		hAxis: {
			format: "dd MMM",
			gridlines: { count: -1 }
		},
		targetAxisIndex: 1
	};
	return {
		datalist: Chart_Return,
		options: options,
		period: return_chart_period
	};
};

export const PerformanceDispatchBreachesData = () => (dispatch, getState) => {
	const dispatch_breaches = getState().performance_store.datalist.charts.dispatch_breaches;
	const breach_chart_period = getState().performance_store.breach_chart_period;
	var options = {
		series: {
			1: {
				type: "line",
				color: "#E82929",
				targetAxisIndex: 0,
				pointSize: 7
			}
		},
		colors: ["#F2B9B8"],
		focusTarget: "category",
		bar: { groupWidth: "50%" },
		legend: { position: "none" },
		hAxis: {
			format: "dd MMM",
			gridlines: { count: -1 }
		},
		targetAxisIndex: 1
	};
	return {
		datalist: dispatch_breaches,
		options: options,
		period: breach_chart_period
	};
};

export const PerformanceCancellationData = () => (dispatch, getState) => {
	const cancellation_chart_period = getState().performance_store.cancellation_chart_period;
	const cancellations = getState().performance_store.datalist.charts.cancellations;
	var options = {
		series: {
			1: {
				type: "line",
				color: "#CC2529",
				targetAxisIndex: 0,
				pointSize: 7
			}
		},
		colors: ["#FF974C"],
		focusTarget: "category",
		bar: { groupWidth: "50%" },
		legend: { position: "none" },
		hAxis: {
			format: "dd MMM",
			gridlines: { count: -1 }
		},
		targetAxisIndex: 1
	};
	return {
		datalist: cancellations,
		options: options,
		period: cancellation_chart_period
	};
};

export const PerformanceDrawChart = (id, options, datalist, period, mounted) => (dispatch, getState) => {
	if (mounted) {
		let data = new google.visualization.DataTable();
		if (data) {
			if (period == "date") data.addColumn("date", "Date");
			else data.addColumn("string", "Date");
			data.addColumn("number", "Quantity");
			data.addColumn("number", "Amount");

			if (Object.keys(datalist).length > 0) {
				let dates = datalist.dates;
				let sales = datalist.gross;
				let qty = datalist.qty;

				for (let i = 0; i < dates.length; i++) {
					if (period == "date") {
						data.addRow([new Date(dates[i]), parseInt(qty[i]), parseInt(sales[i])]);
					} else {
						data.addRow([dates[i], parseInt(qty[i]), parseInt(sales[i])]);
					}
				}
			}
			var chart = new google.visualization.ColumnChart(document.getElementById(id));
			chart.draw(data, options);
		}
	}
};

export const PerformanceUpdateGraph = (type, id, selected_seller) => (dispatch) => {
	nanobar.go(50);
	fetch(ProjectUrl + "/analytics/changegraphperiod", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ grouping: type, chart_type: id, seller: selected_seller })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			nanobar.go(100);
			dispatch({ type: PERFORMANCE_UPDATE_GRAPH, data: data, chart_type: id, chart_period: type });

		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

//Dashboard
export const DashboardGetInitialData = () => (dispatch) => {
	return fetch(ProjectUrl + "/analytics/getdashboarddata", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: DASHBOARD_GET_INITIAL_DATA, data: data });
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};
