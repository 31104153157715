import { connect } from "react-redux";

import Modal from "~/components/common/modal";
import { Button } from "@bit/healthmug.admin.button";
import { Card } from "@bit/healthmug.admin.card";
import { Input } from "@bit/healthmug.admin.input";

import { useLoader } from "~/actions/custom-hooks";

import {
	OrdersOpenManifestModal,
	OrdersDataChange,
	OrdersCheckDate,
	OrdersBatchCreateManifest,
	OrdersAddPrescriptionItem,
	OrdersDeletePrescriptionItem
} from "~/actions/orders-action";

const BatchExpiryModal = (props) => {
	const [create_invoice_button_clicked, start_create_invoice_button_clicked, end_create_invoice_button_clicked] = useLoader(false);

	var prescription_items = props.prescription_data.filter((item) => item.prescription == true);
	var non_prescription_items = props.prescription_data.filter((item) => item.prescription == false);

	const Orders_Batch_Create_Manifest = () => {
		start_create_invoice_button_clicked();
		props.Orders_Batch_Create_Manifest().finally(end_create_invoice_button_clicked);
	};

	return (
		<Modal bgClose={false} escClose={false} id="modalBatchExpiry" className="brandBgLightestGray">
			<div className="uk-grid uk-grid-column-small uk-padding-small">
				<div className="uk-width-expand mediumFont uk-text-bold">Batch & Expiry Details</div>
				<Button onClick={() => props.Orders_Open_Manifest_Modal()} text small>
					<i uk-icon="icon:close" />
				</Button>
			</div>

			<div className="modalBody">
				{prescription_items.length > 0 ? (
					<div>
						<div className="uk-flex uk-margin-small-bottom">
							<div className="rxWrap" style={{ marginRight: "3px" }}>
								<svg>
									<use xlinkHref="/styles/svgsprite.svg#rx"></use>
								</svg>
							</div>
							<div className="uk-flex-grow" style={{ fontSize: "12px", display: "inline-block", verticalAlign: "middle" }}>
								<b>PRESCRIPTION REQUIRED ITEM(S)</b>
							</div>
						</div>
						{prescription_items.map(function (item) {
							var total_input_qty = item.batch_items.reduce((a, b) => a + b.input_qty, 0);
							return (
								<Card key={item.variant_id}>
									<div className="cardBody">
										<div className="uk-grid uk-grid-column-small">
											<div className="uk-width-2-3@s">
												<div className="fontBold">{item.variant_name}</div>
											</div>
											<div className="uk-width-1-3@s">
												<p className="uk-text-right uk-position-relative" style={{ paddingRight: "20px" }}>
													Total Qty : {item.qty}{" "}
													{total_input_qty < item.qty ? (
														<i
															onClick={() => props.Orders_Add_Prescription_Item(item)}
															uk-icon="icon:plus-circle;ratio:0.8"
															className="brandTextPrimary"
															style={{ position: "absolute", right: "0px", top: "3px" }}
														></i>
													) : null}
												</p>
											</div>
										</div>
										{item.batch_items.map(function (batch) {
											return (
												<div className="uk-grid uk-grid-column-small uk-margin-remove-top" key={batch.id}>
													<div className="uk-width-1-6@s uk-margin-small-top">
														<Input
															label="Qty"
															value={batch.input_qty}
															onChange={(e) =>
																props.Orders_Data_Change(item.variant_id, batch.id, "input_qty", e.target.value)
															}
															step="1"
															min="0"
															type="number"
															error={total_input_qty != item.qty ? " " : null}
															required
															small
														/>
													</div>
													<div className="uk-width-1-2@s uk-margin-small-top">
														<Input
															label="Expiry"
															value={batch.expiry}
															onChange={(e) =>
																props.Orders_Data_Change(item.variant_id, batch.id, "expiry", e.target.value)
															}
															type="date"
															error={!props.Orders_Check_Date(batch.expiry) ? " " : null}
															required
															small
														/>
													</div>
													<div className="uk-width-1-6@s uk-margin-small-top">
														<Input
															label="Batch"
															value={batch.batch}
															onChange={(e) =>
																props.Orders_Data_Change(item.variant_id, batch.id, "batch", e.target.value)
															}
															type="text"
															className="form-control size-xs validate"
															required
															small
														/>
													</div>
													{item.batch_items.length > 1 && item.batch_items.length == batch.id ? (
														<div
															className="uk-width-1-6@s uk-margin-small-top uk-position-relative"
															style={{ paddingRight: "20px" }}
														>
															<i
																onClick={() => props.Orders_Delete_Prescription_Item(batch.id, item.variant_id)}
																uk-icon="icon:close"
																className="brandTextDanger"
																style={{ fontSize: "16px", position: "absolute", right: "5", top: "18px" }}
															></i>
														</div>
													) : null}
												</div>
											);
										})}
									</div>
								</Card>
							);
						})}
					</div>
				) : null}
				{non_prescription_items.length > 0 ? (
					<div>
						<div className="uk-margin-small-bottom">
							<b>NON PRESCRIPTION ITEM(S)</b>
						</div>
						{non_prescription_items.map(function (item) {
							var total_input_qty = item.batch_items.reduce((a, b) => a + b.input_qty, 0);
							return (
								<Card key={item.variant_id} className="uk-margin-small-bottom">
									<div className="cardBody">
										<div className="uk-grid uk-grid-column-small">
											<div className="uk-width-2-3@s">
												<div className="fontBold">{item.variant_name}</div>
											</div>
											<div className="uk-width-1-3@s">
												<p className="uk-text-right uk-position-relative" style={{ paddingRight: "20px" }}>
													Total Qty : {item.qty}{" "}
													{total_input_qty < item.qty ? (
														<i
															onClick={() => props.Orders_Add_Prescription_Item(item)}
															uk-icon="icon:plus-circle;ratio:0.8"
															className="brandTextPrimary"
															style={{ fontSize: "16px", position: "absolute", right: "0px", top: "3px" }}
														></i>
													) : null}
												</p>
											</div>
										</div>
										{item.batch_items.map(function (batch) {
											return (
												<div className="uk-grid uk-grid-column-small uk-margin-remove-top" key={batch.id}>
													<div className="uk-width-1-6@s uk-margin-small-top">
														<Input
															label="Qty"
															value={batch.input_qty}
															onChange={(e) =>
																props.Orders_Data_Change(item.variant_id, batch.id, "input_qty", e.target.value)
															}
															step="1"
															min="0"
															type="number"
															error={total_input_qty != item.qty ? " " : null}
															required
															small
														/>
													</div>
													<div className="uk-width-1-2@s uk-margin-small-top">
														<Input
															label="Expiry"
															value={batch.expiry}
															onChange={(e) =>
																props.Orders_Data_Change(item.variant_id, batch.id, "expiry", e.target.value)
															}
															type="date"
															error={!props.Orders_Check_Date(batch.expiry) ? " " : null}
															small
														/>
													</div>
													<div className="uk-width-1-6@s uk-margin-small-top">
														<Input
															label="Batch"
															value={batch.batch}
															onChange={(e) =>
																props.Orders_Data_Change(item.variant_id, batch.id, "batch", e.target.value)
															}
															type="text"
															className="form-control size-xs validate"
															small
														/>
													</div>
													{item.batch_items.length > 1 && item.batch_items.length == batch.id ? (
														<div
															className="uk-width-1-6@s uk-margin-small-top uk-position-relative"
															style={{ paddingRight: "20px" }}
														>
															<i
																onClick={() => props.Orders_Delete_Prescription_Item(batch.id, item.variant_id)}
																uk-icon="icon:close"
																className="brandTextDanger"
																style={{ position: "absolute", right: "5", top: "18px" }}
															></i>
														</div>
													) : null}
												</div>
											);
										})}
									</div>
								</Card>
							);
						})}
					</div>
				) : null}
			</div>
			<div className="modalFooter transparent border uk-text-right">
				<Button primary onClick={Orders_Batch_Create_Manifest} loader={create_invoice_button_clicked} className="btn btn-healthmug size-md">
					Create & Print Invoice
				</Button>
			</div>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	prescription_data: state.orders_store.prescription_data
});
const mapDispatchToProps = (dispatch) => ({
	Orders_Open_Manifest_Modal: () => dispatch(OrdersOpenManifestModal()),
	Orders_Add_Prescription_Item: (item) => dispatch(OrdersAddPrescriptionItem(item)),
	Orders_Data_Change: (variant_id, id, name, value) => dispatch(OrdersDataChange(variant_id, id, name, value)),
	Orders_Check_Date: (expiry_date) => dispatch(OrdersCheckDate(expiry_date)),
	Orders_Delete_Prescription_Item: (batch_id, variant_id) => dispatch(OrdersDeletePrescriptionItem(batch_id, variant_id)),
	Orders_Batch_Create_Manifest: () => dispatch(OrdersBatchCreateManifest())
});
export default connect(mapStateToProps, mapDispatchToProps)(BatchExpiryModal);
