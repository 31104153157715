import { connect } from "react-redux";

import { Button } from "@bit/healthmug.admin.button";

import {
	OrdersBodyCheckAll,
	OrdersBodyTickAll,
	OrdersClickOrder,
	OrdersBodyTickFunction,
	OrdersValue,
	OrdersQty,
	OrdersBodyOpenModal,
	OrdersManifestItems,
	HubOrdersCancelIssue
} from "~/actions/orders-action";

import CreateCard from "~/components/orders-component/create-card";

const OrderCommonView = (props) => {
	return (
		<div className="uk-container uk-container-expand pageHeight">
			{props.page_type == "new" ? (
				<div className="marginTopMd">
					<label>
						<input
							type="checkbox"
							onChange={() => props.Orders_Body_Check_All()}
							checked={props.Orders_Body_Tick_All()}
							className="uk-checkbox"
						/>{" "}
						Select All
					</label>
				</div>
			) : null}
			{props.order_list.map(function (order, i) {
				return (
					<div className="cardGroup gap dataBox" key={i}>
						<div className="cardGroupTitle">
							<div className="uk-grid uk-grid-column-small">
								<div className="uk-width-expand">
									<div className="uk-grid uk-grid-column-small">
										<div className="uk-width-auto">
											{props.page_type == "new" ? (
												<input
													type="checkbox"
													style={{ margin: "0px" }}
													onChange={() => props.Orders_Click_Order(order.order_id)}
													checked={props.Orders_Body_Tick_Function(order.order_id)}
													className="uk-checkbox"
												/>
											) : null}
										</div>
										<div className="uk-width-expand">
											<div>
												<span className="fontBold">
													{order.order_id} (Total Value: {props.Orders_Value(order)}, Total Qty: {props.Orders_Qty(order)})
												</span>
												&nbsp;
												<div className="customTooltip">
													<i uk-icon="icon:info;ratio:0.7" />
													<div className="customTooltipData" uk-drop="delay-hide:100;offset:2">
														<div className="fontBold">Buyer Info</div>
														<div className="uk-margin-small-top addressInfo">
															{order.buyer.name}
															<br />
															{order.buyer.address},<br />
															{order.buyer.city},<br /> {order.buyer.state}-{order.buyer.pin}
														</div>
													</div>
												</div>
											</div>
											<div>
												{order.order_date} ({order.payment_mode}){" "}
												{props.hub == "true" ? (
													<>
														{order.packet_count > 0 ? (
															<span className="brandTextDanger" style={{ marginLeft: "15px" }}>
																Packet already created: {order.packet_count}
															</span>
														) : null}
													</>
												) : null}
											</div>
										</div>
									</div>
								</div>

								{props.page_type == "upcoming" || props.page_type == "new" ? (
									<div className="uk-width-auto">
										{order.high_priority == true ? (
											<span
												title="High Priority"
												className="fastDelivery"
												style={{ display: "inline-block", verticalAlign: "middle", marginRight: "6px" }}
											>
												<svg>
													<use xlinkHref="/styles/svgsprite.svg#delivery"></use>
												</svg>
											</span>
										) : null}
										{order.has_ticket == true ? (
											<span
												title="Ticket Present"
												className="brandTextWarning"
												style={{ display: "inline-block", verticalAlign: "middle", marginRight: "6px" }}
											>
												<svg style={{ fill: "currentColor", height: "20px", width: "20px" }}>
													<use xlinkHref="/styles/svgsprite.svg#issue"></use>
												</svg>
											</span>
										) : null}
										{props.page_type == "new" ? (
											<div style={{ display: "inline-block", verticalAlign: "middle" }}>
												{props.manifest_order == order.order_id ? (
													<div>
														{props.selected_items_length > 0 ? (
															<Button
																onClick={() => props.Orders_Body_Open_Modal("#modalCancel")}
																micro
																danger
																className="uk-margin-small-left"
															>
																Cancel Items
															</Button>
														) : null}
														{props.selected_items_length > 0 ? (
															<Button
																onClick={() => props.Orders_Body_Open_Modal("#modalManifest")}
																primary
																micro
																className="uk-margin-small-left"
															>
																Manifest Items
															</Button>
														) : null}
														<Button
															onClick={() => props.Orders_Manifest_Items(order)}
															primary
															micro
															className="uk-margin-small-left"
														>
															&#10005;
														</Button>
													</div>
												) : props.hub == "true" && order.issue_order ? (
													<Button
														onClick={() => props.Hub_Orders_Cancel_Issue(order)}
														primary
														micro
														className="uk-margin-small-left"
													>
														Cancel Issue
													</Button>
												) : (
													<Button
														onClick={() => props.Orders_Manifest_Items(order)}
														primary
														micro
														className="uk-margin-small-left"
													>
														Edit Items
													</Button>
												)}
											</div>
										) : null}
									</div>
								) : null}
								<div className="uk-inline-block">
									<img
										src={"https://static.oxinis.com/image/logo/" + order.website + "-logo-50.webp"}
										style={{ width: "25px", height: "auto" }}
									/>
								</div>
							</div>
						</div>

						{order.itemlist.map((item, i) => (
							<CreateCard item={item} order_id={order.order_id} issue_order={order.issue_order} key={i} />
						))}
					</div>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => ({
	hub: state.orders_store.hub,
	page_type: state.orders_store.datalist.pagetype,
	order_list: state.orders_store.datalist.orderlist,
	manifest_order: state.orders_store.manifest_order,
	selected_items_length: state.orders_store.selected_items.length
});
const mapDispatchToProps = (dispatch) => ({
	Orders_Body_Check_All: () => dispatch(OrdersBodyCheckAll()),
	Orders_Body_Tick_All: () => dispatch(OrdersBodyTickAll()),
	Orders_Click_Order: (id) => dispatch(OrdersClickOrder(id)),
	Orders_Body_Tick_Function: (id) => dispatch(OrdersBodyTickFunction(id)),
	Orders_Value: (order) => dispatch(OrdersValue(order)),
	Orders_Qty: (order) => dispatch(OrdersQty(order)),
	Orders_Body_Open_Modal: (modal) => dispatch(OrdersBodyOpenModal(modal)),
	Orders_Manifest_Items: (order) => dispatch(OrdersManifestItems(order)),
	Hub_Orders_Cancel_Issue: () => dispatch(HubOrdersCancelIssue())
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderCommonView);
