import { connect } from "react-redux";

import Modal from "~/components/common/modal";
import { Button } from "@bit/healthmug.admin.button";

import { OrdersCheckAllOrders } from "~/actions/orders-action";

const CheckAllOrdersModal = (props) => {
	return (
		<Modal micro closeButtonOutside bgClose={false} escClose={false} id="modalCheckallOrders">
			<div className="modalBody uk-text-center">
				<div className="uk-padding-small">
					<div className="mediumFont uk-margin-small-bottom">Do you really want to select all orders?</div>

					<Button
						onClick={() => props.Orders_Check_All_Orders()}
						primary
						small
						className="uk-width-1-1 uk-margin-small-top uk-margin-small-bottom"
					>
						Ok
					</Button>
					<Button small className="uk-width-1-1 uk-margin-small-top uk-margin-small-bottom uk-modal-close">
						Cancel
					</Button>
				</div>
			</div>
		</Modal>
	);
};

const mapDispatchToProps = (dispatch) => ({
	Orders_Check_All_Orders: () => dispatch(OrdersCheckAllOrders())
});
export default connect(null, mapDispatchToProps)(CheckAllOrdersModal);
