import UIkit from "uikit";

import { PROFILE_EDIT_ERROR_LIST, PROFILE_CLEAR_LOCAL_STATE } from "~/actions/action-types";
import { PROFILE_SET_SELLER_SUPPORT_CODE } from "~/actions/action-types";
import { PROFILE_CHANGE_PASSWORD_DATA, PROFILE_CLEAR_PASSWORD_DATA } from "~/actions/action-types";
import { PROFILE_GET_PICKUP_INITIAL_DATA } from "~/actions/action-types";
import { PROFILE_GET_BUSINESS_INITIAL_DATA, PROFILE_SET_IMAGE_SOURCE } from "~/actions/action-types";
import { PROFILE_GET_CONTACT_INITIAL_DATA, PROFILE_EDIT_CONTACT_DETAILS } from "~/actions/action-types";
import { PROFILE_GET_CALENDER_INITIAL_DATA, PROFILE_EDIT_OFFICE_TIMINGS } from "~/actions/action-types";
import {
	PROFILE_GET_HOLIDAY_INFO_INITIAL_DATA,
	PROFILE_RELOAD_HOLIDAY_DATA,
	PROFILE_CLEAR_ENTERED_HOLIDAY_LIST,
	PROFILE_EDIT_ENTERED_HOLIDAY_LIST
} from "~/actions/action-types";

import { ProjectUrl } from "~/env";

//Common
export const ProfileGetInitialData = (type) => (dispatch) => {
	dispatch({ type: PROFILE_CLEAR_LOCAL_STATE });
	const url =
		type == "contact_info"
			? "/profile/getcontactinfo"
			: type == "business_detail"
			? "/profile/getbusinessinfo"
			: type == "calendar_info"
			? "/profile/getcalendarinfo"
			: type == "pickup_info"
			? "/profile/getpickupinfo"
			: "/profile/getholidaylist";
	const initial_action_type =
		type == "contact_info"
			? PROFILE_GET_CONTACT_INITIAL_DATA
			: type == "business_detail"
			? PROFILE_GET_BUSINESS_INITIAL_DATA
			: type == "calendar_info"
			? PROFILE_GET_CALENDER_INITIAL_DATA
			: type == "pickup_info"
			? PROFILE_GET_PICKUP_INITIAL_DATA
			: PROFILE_GET_HOLIDAY_INFO_INITIAL_DATA;
	return fetch(ProjectUrl + url, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: initial_action_type, data: data });
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

// contact Info Actions
const ContactFetchData = (contact_name, contact_email, contact_mobile) => {
	fetch(ProjectUrl + "/profile/setcontactinfo", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({
			email: contact_email,
			mobile: contact_mobile,
			name: contact_name
		})
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then(() => {
			alertify.success("Primary Contact Detail Saved");
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const ContactOnChange = (key, value) => (dispatch) => {
	dispatch({ type: PROFILE_EDIT_CONTACT_DETAILS, key, value });
};

export const ContactOnFocus = (key) => (dispatch, getState) => {
	let error_list = getState().profile_common_store.error_list;

	if (key === "contact_name") {
		delete error_list["contact_name"];
	}
	if (key === "contact_email") {
		delete error_list["contact_email"];
	}
	if (key === "contact_mobile") {
		delete error_list["contact_mobile"];
	}
	dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list });
};

export const ContactValidation = () => (dispatch, getState) => {
	const contact_name = getState().profile_contact_store.datalist.contact_name;
	const contact_email = getState().profile_contact_store.datalist.contact_email;
	const contact_mobile = getState().profile_contact_store.datalist.contact_mobile;
	let email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,3})$/;
	let error_list = getState().profile_common_store.error_list;

	if (contact_name.length === 0) {
		error_list["contact_name"] = "Contact Name cannot be empty";
	} else if (contact_name.length < 3) {
		error_list["contact_name"] = "Name cannot be less than 3 character";
	}
	if (contact_email.length === 0) {
		error_list["contact_email"] = "Contact Email cannot be empty";
	} else if (!email_validator.test(contact_email)) {
		error_list["contact_email"] = "Invalid Email";
	} else if (contact_mobile.length === 0) {
		error_list["contact_mobile"] = "Mobile No. cannot be empty";
	} else if (contact_mobile < 6000000000 || contact_mobile > 9999999999) {
		error_list["contact_mobile"] = "Enter valid mobile no.";
	}
	if (Object.keys(error_list).length > 0) {
		dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list: error_list });
	}
	if (Object.keys(error_list).length === 0) {
		ContactFetchData(contact_name, contact_email, contact_mobile);
	}
};

//Change Password Actions
const ChangePasswordFetchData = (current_password, new_password, confirm_password) => (dispatch) => {
	fetch(ProjectUrl + "/profile/setnewpassword", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({
			oldpassword: current_password,
			newpassword: new_password,
			confirmpassword: confirm_password
		})
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then(() => {
			alertify.success("Password Changed");
			dispatch({ type: PROFILE_CLEAR_PASSWORD_DATA });
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const ChangePasswordOnChange = (key, value) => (dispatch) => {
	dispatch({ type: PROFILE_CHANGE_PASSWORD_DATA, key, value });
};

export const ChangePasswordOnFocus = (key) => (dispatch, getState) => {
	let error_list = getState().profile_common_store.error_list;

	if (key === "current_password") {
		delete error_list["current_password"];
	}
	if (key === "new_password") {
		delete error_list["new_password"];
	}
	if (key === "confirm_password") {
		delete error_list["confirm_password"];
	}
	dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list: error_list });
};

export const ChangePasswordValidation = () => (dispatch, getState) => {
	const current_password = getState().profile_change_password_store.current_password;
	const new_password = getState().profile_change_password_store.new_password;
	const confirm_password = getState().profile_change_password_store.confirm_password;
	let error_list = getState().profile_common_store.error_list;
	let password_validator = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$&_~-])[a-zA-Z0-9#!@$&_~-]{10,}$/;

	if (current_password.length === 0) {
		error_list["current_password"] = "Current password cannot be empty";
	} else if (current_password.length < 6) {
		error_list["current_password"] = "Current password cannot be Less than 6 character";
	}
	if (new_password.length === 0) {
		error_list["new_password"] = "New password cannot be empty";
	} else if (new_password.length < 10) {
		error_list["new_password"] = "New password cannot be less than 10 character";
	} else if (!password_validator.test(new_password)) {
		error_list["new_password"] = "Password should be at least 1 capital letter, 1 small letter, 1 number and 1 special symbol from #!@$&_~-";
	}
	if (confirm_password.length === 0) {
		error_list["confirm_password"] = "Confirm password cannot be empty";
	}
	if (new_password.length >= 10) {
		if (new_password !== confirm_password) {
			error_list["confirm_password"] = "New password & confirm password should be same";
		}
	}
	if (Object.keys(error_list).length > 0) {
		dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list: error_list });
	} else if (Object.keys(error_list).length === 0) {
		dispatch(ChangePasswordFetchData(current_password, new_password, confirm_password));
	}
};

//Calendar Actions
const CalendarFetchData = (from_hours, from_min, to_hours, to_min, weekly_off) => {
	fetch(ProjectUrl + "/profile/setcalendarinfo", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({
			working_hours_from: from_hours + ":" + from_min,
			working_hours_to: to_hours + ":" + to_min,
			weekly_off: weekly_off
		})
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then(() => {
			alertify.success("Calendar Detail Saved");
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const CalendarOnChange = (key, value) => (dispatch) => {
	const Time = new Date(value[0]);
	let hour = Time.getHours();
	let min = Time.getMinutes();
	if (hour < 10 || min < 10) {
		if (min < 10) {
			min = "0" + min;
		}
		if (hour < 10) {
			hour = "0" + hour;
		}
	}
	if (key === "from") {
		dispatch({ type: PROFILE_EDIT_OFFICE_TIMINGS, key: "from_hours", value: `${hour}` });
		dispatch({ type: PROFILE_EDIT_OFFICE_TIMINGS, key: "from_min", value: `${min}` });
	}
	if (key === "to") {
		dispatch({ type: PROFILE_EDIT_OFFICE_TIMINGS, key: "to_hours", value: `${hour}` });
		dispatch({ type: PROFILE_EDIT_OFFICE_TIMINGS, key: "to_min", value: `${min}` });
	}
	if (key === "weekly_off") {
		const weekly_off_Value = parseInt(value);
		dispatch({ type: PROFILE_EDIT_OFFICE_TIMINGS, key, value: weekly_off_Value });
	}
};

export const CalendarValidation = () => (dispatch, getState) => {
	const from_hours = getState().profile_calendar_store.datalist.from_hours;
	const to_hours = getState().profile_calendar_store.datalist.to_hours;
	const from_min = getState().profile_calendar_store.datalist.from_min;
	const to_min = getState().profile_calendar_store.datalist.to_min;
	const weekly_off = getState().profile_calendar_store.datalist.weekly_off;
	let error_list = getState().profile_common_store.error_list;

	if (parseInt(from_hours) >= parseInt(to_hours)) {
		error_list["from"] = "From hour should be less then To hours";
	}
	if (Object.keys(error_list).length > 0) {
		dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list: error_list });
	} else if (Object.keys(error_list).length === 0) {
		CalendarFetchData(from_hours, from_min, to_hours, to_min, weekly_off);
	}
};

export const CalendarOnFocus = () => (dispatch, getState) => {
	let error_list = getState().profile_common_store.error_list;

	if (error_list["from"] != "") {
		delete error_list["from"];
	}
	dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list: error_list });
};

//Holiday Info Actions
const GetHolidayList = () => (dispatch) => {
	nanobar.go(50);
	fetch(ProjectUrl + "/profile/getholidaylist", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: PROFILE_RELOAD_HOLIDAY_DATA, data });
			nanobar.go(100);
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const HolidayOnChange = (key, value) => (dispatch) => {
	dispatch({ type: PROFILE_EDIT_ENTERED_HOLIDAY_LIST, key, value });
};

export const HolidayOnFocus = (key) => (dispatch, getState) => {
	let error_list = getState().profile_common_store.error_list;

	if (error_list[key] != "") {
		delete error_list[key];
	}
	dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list: error_list });
};

export const DeleteHoliday = (id) => (dispatch) => {
	fetch(ProjectUrl + "/profile/deleteholidayinfo", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ id: id })
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then(() => {
			alertify.success("Holiday Deleted");
			dispatch(GetHolidayList());
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const SaveHolidayList = () => (dispatch, getState) => {
	let entered_holiday_list = getState().profile_holiday_store.entered_holiday_list;
	let error_list = getState().profile_common_store.error_list;

	if (entered_holiday_list === "") {
		error_list["entered_holiday_list"] = "Holiday Date cannot be empty";
	}
	if (Object.keys(error_list).length > 0) {
		dispatch({ type: PROFILE_EDIT_ERROR_LIST, error_list });
	} else if (Object.keys(error_list).length === 0) {
		let error = "";
		var regex = /^(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((20)\d\d))$/;
		let holidays = entered_holiday_list.split("\n");

		for (var i = 0; i < holidays.length; i++) {
			let holiday_date = holidays[i].split("/");
			if (regex.test(holidays[i]) == false) {
				error = "Invalid date format for " + holidays[i];
				break;
			} else if (new Date(holiday_date[2] + "-" + holiday_date[1] + "-" + holiday_date[0]) < new Date()) {
				error = "Holiday Date should be after todays date : " + holidays[i];
				break;
			}
		}

		if (error != "") alertify.error(error);
		else {
			fetch(ProjectUrl + "/profile/setholidayinfo", {
				method: "POST",
				credentials: "include",
				headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
				body: JSON.stringify({ holiday_date: entered_holiday_list })
			})
				.then((res) => (res.ok ? res.text() : Promise.reject(res)))
				.then(() => {
					alertify.success("Holiday Detail Saved");
					dispatch({ type: PROFILE_CLEAR_ENTERED_HOLIDAY_LIST, value: "" });
					dispatch(GetHolidayList());
				})
				.catch((error) => {
					if (error instanceof Error) alertify.error(error);
					else error.text().then((data) => alertify.error(data));
				});
		}
	}
};

//Help (Seller Support )
export const GenerateSellerSupportCode = () => (dispatch) => {
	fetch(ProjectUrl + "/home/generatesellersupportcode", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: PROFILE_SET_SELLER_SUPPORT_CODE, value: data });
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
			dispatch({ type: PROFILE_SET_SELLER_SUPPORT_CODE, value: "" });
		});
};

//BUsiness Details
export const OpenZoomImg = (src) => (dispatch) => {
	dispatch({ type: PROFILE_SET_IMAGE_SOURCE, value: src });
	UIkit.modal("#zoomimg").show();
};
