import { ProjectUrl } from "~/env";

import { GetWebsitesData } from "~/actions/common-action";
import { USER_INFO_GET_DATA, RESET_INITIAL_STATE, LOGIN_SET_ERROR_LIST, LOGIN_SET_EMAIL_OR_PASSWORD } from "~/actions/action-types";

export const GetUserDetails = () => (dispatch, getState) => {
	const seller_id_code = getState().user_info_store.id_code;
	return fetch(ProjectUrl + "/home/getuserdetails", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			if (data.logged_in_success) {
				if (data.id_code != seller_id_code) {
					dispatch({ type: RESET_INITIAL_STATE });
				}
				dispatch({ type: USER_INFO_GET_DATA, data: data });
				dispatch(GetWebsitesData());
			}
			return Promise.resolve(data.logged_in_success);
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const LoginSetEmailOrPassword = (field, value) => ({ type: LOGIN_SET_EMAIL_OR_PASSWORD, field: field, value: value });

export const LoginSetErrorList = (field, message) => ({ type: LOGIN_SET_ERROR_LIST, field: field, mode: "add", message: message });

export const LoginCheckValidation = () => (dispatch, getState) => {
	let email = getState().email;
	let password = getState().password;
	let email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
	let password_validator = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$&_~-])[a-zA-Z0-9#!@$&_~-]{10,}$/;
	if (email == "") {
		dispatch(LoginSetErrorList("email", "Enter User Id"));
	} else if (!email_validator.test(email)) {
		dispatch(LoginSetErrorList("email", "Enter a valid User Id"));
	}
	if (password == "") {
		dispatch(LoginSetErrorList("password", "Enter password"));
	} else if (!password_validator.test(password)) {
		dispatch(LoginSetErrorList("password", "Password should contain at least one capital, small, numeric and special characters #!@$&_~-"));
	}
	if (email != "" && email_validator.test(email) && password != "" && password.length >= 10 && password_validator.test(password)) {
		return fetch(ProjectUrl + "/home/userlogin", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ email: email, pwd: password })
		})
			.then((res) => (res.ok ? res.json() : Promise.reject(res)))
			.then((data) => {
				if (data) {
					return Promise.resolve(data);
				} else {
					alertify.error("Not a valid user");
					return Promise.resolve(data);
				}
			})
			.catch((error) => {
				if (error instanceof Error) alertify.error(error);
				else error.text().then((data) => alertify.error(data));
				return Promise.reject();
			});
	} else {
		return Promise.reject();
	}
};

export const LoginOnFocus = (field) => ({ type: LOGIN_SET_ERROR_LIST, field: field, mode: "delete" });

export const Logout = () => (dispatch) => {
	return fetch(ProjectUrl + "/home/logout", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? dispatch({ type: RESET_INITIAL_STATE }) : Promise.reject(res)))
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

export const ChangeSeller = (seller_id_code) => (dispatch) => {
	return fetch(ProjectUrl + "/home/seller-change", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ seller_id_code: seller_id_code })
	})
		.then((res) => (res.json ? res.json() : Promise.reject(res)))
		.then((data) => {
			if (data.logged_in_success) {
				dispatch({ type: RESET_INITIAL_STATE });
				dispatch({ type: USER_INFO_GET_DATA, data: data });
			}
			return Promise.resolve(data.logged_in_success);
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};
