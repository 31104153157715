import { ProjectUrl } from "~/env";

import { FORGOT_PASSWORD_SET_EMAIL_OR_PASSWORD, FORGOT_PASSWORD_SET_ERROR_LIST } from "~/actions/action-types";

import { LoginSetErrorList } from "~/actions/login-action";

export const ForgotPasswordSetEmailOrPassword = (field, value) => ({ type: FORGOT_PASSWORD_SET_EMAIL_OR_PASSWORD, field: field, value: value });

export const ForgotPasswordSetErrorList = (field, message) => ({ type: FORGOT_PASSWORD_SET_ERROR_LIST, field: field, mode: "add", message: message });

export const ForgotPasswordOnFocus = (field) => ({ type: FORGOT_PASSWORD_SET_ERROR_LIST, field: field, mode: "delete" });

export const ForgotPasswordValidation = (email) => (dispatch) => {
	let email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
	if (email == "") {
		dispatch(LoginSetErrorList("email", "Enter email for reset password"));
		return Promise.reject();
	} else if (!email_validator.test(email)) {
		dispatch(LoginSetErrorList("email", "Enter a valid User Id"));
		return Promise.reject();
	} else {
		return fetch(ProjectUrl + "/home/forgotpasswordlink", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ email: email })
		})
			.then((res) => (res.ok ? res.text() : Promise.reject(res)))
			.catch((error) => {
				if (error instanceof Error) alertify.error(error);
				else error.text().then((data) => alertify.error(data));
				return Promise.reject();
			});
	}
};

export const ForgotPasswordCheckValidation = (code) => (dispatch, getState) => {
	let email = getState().email;
	let password = getState().password;
	let email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
	let password_validator = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$&_~-])[a-zA-Z0-9#!@$&_~-]{10,}$/;

	if (email == "") {
		dispatch(ForgotPasswordSetErrorList("email", "Enter User Id"));
	} else if (!email_validator.test(email)) {
		dispatch(ForgotPasswordSetErrorList("email", "Enter a valid User Id"));
	}
	if (password == "") {
		dispatch(ForgotPasswordSetErrorList("password", "Enter password"));
	} else if (password.length < 10) {
		dispatch(ForgotPasswordSetErrorList("password", "Password should be more than 10 characters"));
	} else if (!password_validator.test(password)) {
		dispatch(
			ForgotPasswordSetErrorList(
				"password",
				"Password should be at least 1 capital letter, 1 small letter, 1 number and 1 special symbol from #!@$&_~-"
			)
		);
	}
	if (email != "" && password != "" && password.length >= 10 && email_validator.test(email) && password_validator.test(password)) {
		return fetch(ProjectUrl + "/home/resetpassword", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ email: email, pwd: password, code: code })
		})
			.then((res) => (res.ok ? res.text() : Promise.reject(res)))
			.catch((error) => {
				if (error instanceof Error) alertify.error(error);
				else error.text().then((data) => alertify.error(data));
				return Promise.reject();
			});
	} else {
		return Promise.reject();
	}
};
