import { connect } from "react-redux";

import { Button } from "@bit/healthmug.admin.button";
import { Card } from "@bit/healthmug.admin.card";

import {
	OrdersCardItemSelect,
	OrdersCardTickFunction,
	OrdersChangeMrp,
	OrdersCheckMrp,
	OrdersEditMrp,
	OrdersCancelOptionSelected,
	OrdersCancelQtyChange,
	OrdersCancelOptionValues,
	OrdersCardClass
} from "~/actions/orders-action";

const CreateCard = ({ key, item, order_id, issue_order, ...props }) => {
	const error = props.hub === "false" ? props.Orders_Card_Class(item.dispatch_by) : item.sla_breached ? true : false;
	return (
		<Card error={error} key={key}>
			<div className="cardBody">
				<div className="uk-grid uk-grid-column-small">
					<div className="uk-width-auto@s uk-text-center@s uk-text-center">
						<div className="productImage productImgCheckbox">
							{props.manifest_order == order_id ? (
								<input
									onChange={() => props.Orders_Card_Item_Select(item)}
									className="uk-checkbox"
									type="checkbox"
									id={"chk" + key}
									checked={props.Orders_Card_Tick_Function(item.order_item_id)}
								/>
							) : null}
							<img src={"https://static.oxinis.com/healthmug/image/product/" + item.image + "-200.jpg"} />
						</div>
					</div>

					<div className="uk-width-expand@s">
						<div className="uk-grid uk-grid-column-small">
							<div className="uk-width-1-3@s">
								<div className="groupTitle">
									<span onClick={() => props.Orders_Card_Item_Select(item)}>
										<b>{item.name}</b>
									</span>
								</div>
								{item.is_allopathy ? (
									<div className="labelValue inline">
										<label>Brand</label> {item.brand}
									</div>
								) : null}
								<div className="labelValue inline">
									<label>SKU</label> {item.sku}
								</div>
								<div className="labelValue inline">
									<label>Order Item Id</label>
									{item.order_item_id}
								</div>
								{item.manufacturer_part_no != null ? (
									<div className="labelValue inline">
										<label>Manf. Code</label> {item.manufacturer_part_no}
									</div>
								) : null}
								{item.weight != 0 ? (
									<div className="labelValue inline">
										<label>Weight</label> {item.weight}
									</div>
								) : null}
								{item.prescription_required ? (
									<div style={{ marginTop: "10px" }}>
										<span className="rxWrap">
											<svg>
												<use xlinkHref="/styles/svgsprite.svg#rx"></use>
											</svg>
										</span>{" "}
										<span className="rxText">Prescription Required</span>
									</div>
								) : null}
							</div>

							<div className="uk-width-1-3@s">
								<div className="labelValue inline inlineEditable">
									<label>MRP(₹)</label>
									{item.mrp}
									{props.hub == "true" ? (
										<>
											{props.order_item_id_code == item.order_item_id && props.mrp_edit_confirmation && issue_order == false ? (
												<div className="editqty">
													<input
														type="number"
														min="0"
														max="500"
														onChange={(e) => props.Orders_Change_Mrp(e.target.value)}
														className="form-control size-xs"
													/>
													<Button onClick={() => props.Orders_Check_Mrp()} primary micro>
														Save
													</Button>
												</div>
											) : null}
										</>
									) : (
										<>
											{props.order_item_id_code == item.order_item_id && props.mrp_edit_confirmation ? (
												<div className="editqty">
													<input
														type="number"
														min="0"
														max="500"
														onChange={(e) => props.Orders_Change_Mrp(e.target.value)}
														className="form-control size-xs"
													/>
													<Button onClick={() => props.Orders_Check_Mrp()} primary micro>
														Save
													</Button>
												</div>
											) : null}
										</>
									)}
									&nbsp;
									{props.page_type == "upcoming" ? null :
										<>
											{
												props.hub == "true" ? (
													<>
														{issue_order ? null : (
															<a>
																{props.order_item_id_code == item.order_item_id && props.mrp_edit_confirmation ? (
																	<i
																		className="brandTextDanger"
																		uk-icon="icon: close;ratio:0.7"
																		onClick={() => props.Orders_Edit_Mrp(item.order_item_id)}
																	/>
																) : (
																	<i
																		uk-icon="icon:pencil;ratio:0.7"
																		onClick={() => props.Orders_Edit_Mrp(item.order_item_id)}
																	/>
																)}
															</a>
														)}
													</>
												) : (
													<>
														{props.order_item_id_code == item.order_item_id && props.mrp_edit_confirmation ? (
															<i
																className="brandTextDanger"
																uk-icon="icon: close;ratio:0.7"
																onClick={() => props.Orders_Edit_Mrp(item.order_item_id)}
															/>
														) : (
															<i uk-icon="icon:pencil; ratio:0.7" onClick={() => props.Orders_Edit_Mrp(item.order_item_id)} />
														)}
													</>
												)
											}
										</>
									}
								</div>
								<div className="labelValue inline">
									<label>Price(₹)</label> {item.price}
								</div>
								<div className="labelValue inline inlineEditable">
									<label>Qty</label>
									<span
										style={
											item.qty > 1
												? {
													display: "inline-block",
													width: "20px",
													textAlign: "center",
													backgroundColor: "#ffa707",
													color: "#000"
												}
												: null
										}
									>
										{item.qty}
									</span>
									{props.manifest_order == order_id && props.Orders_Card_Tick_Function(item.order_item_id) ? (
										<div className="editqty">
											<span className="brandTextMediumGray" style={{ width: "auto", marginRight: "3px", marginLeft: "3px" }}>
												Select Qty. to cancel{" "}
											</span>
											<select
												className="form-control qtySelect"
												value={props.Orders_Cancel_Option_Selected(item.order_item_id)}
												onChange={(e) => props.Orders_Cancel_Qty_Change(item.order_item_id, e.target.value)}
											>
												{props.Orders_Cancel_Option_Values(item.qty)}
											</select>
										</div>
									) : null}
								</div>
								<div className="labelValue inline">
									<label>Total(₹)</label> {item.total}
								</div>
							</div>
							{props.page_type != "upcoming" ? (
								<div className="uk-width-1-3@s">
									<div className="brandTextMediumGray">Dispatch By</div>
									<div className={error ? "brandTextDanger" : null}>{item.dispatch_by}</div>
									<div className="brandTextMediumGray uk-margin-small-top">SLA</div>
									<div className="brandTextDarkGray">{item.sla} days</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</Card>
	);
};

const mapStateToProps = (state) => ({
	hub: state.orders_store.hub,
	page_type: state.orders_store.datalist.pagetype,
	manifest_order: state.orders_store.manifest_order,
	order_item_id_code: state.orders_store.order_item_id_code,
	mrp_edit_confirmation: state.orders_store.mrp_edit_confirmation
});
const mapDispatchToProps = (dispatch) => ({
	Orders_Check_Mrp: () => dispatch(OrdersCheckMrp()),
	Orders_Change_Mrp: (value) => dispatch(OrdersChangeMrp(value)),
	Orders_Edit_Mrp: (id_code) => dispatch(OrdersEditMrp(id_code)),
	Orders_Card_Item_Select: (item) => dispatch(OrdersCardItemSelect(item)),
	Orders_Card_Tick_Function: (id) => dispatch(OrdersCardTickFunction(id)),
	Orders_Card_Class: (dispatch_by) => dispatch(OrdersCardClass(dispatch_by)),
	Orders_Cancel_Option_Values: (qty) => dispatch(OrdersCancelOptionValues(qty)),
	Orders_Cancel_Qty_Change: (order_item_id, value) => dispatch(OrdersCancelQtyChange(order_item_id, value)),
	Orders_Cancel_Option_Selected: (order_item_id) => dispatch(OrdersCancelOptionSelected(order_item_id))
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateCard);
