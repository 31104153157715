//Rehydration Store
export const REHYDRATION_SET_REHYDRATION_VALUE = "REHYDRATION_SET_REHYDRATION_VALUE";

//User Info
export const USER_INFO_GET_DATA = "USER_INFO_GET_DATA";
export const SET_SELECTED_SELLER = "SET_SELECTED_SELLER";

//logout
export const RESET_INITIAL_STATE = "RESET_INITIAL_STATE";

//login
export const LOGIN_SET_ERROR_LIST = "LOGIN_SET_ERROR_LIST";
export const LOGIN_SET_EMAIL_OR_PASSWORD = "LOGIN_SET_EMAIL_OR_PASSWORD";

//Forgot Password
export const FORGOT_PASSWORD_SET_ERROR_LIST = "FORGOT_PASSWORD_SET_ERROR_LIST";
export const FORGOT_PASSWORD_SET_EMAIL_OR_PASSWORD = "FORGOT_PASSWORD_SET_EMAIL_OR_PASSWORD";

//seller Support
export const SELLER_SUPPORT_SET_ERROR_LIST = "SELLER_SUPPORT_SET_ERROR_LIST";
export const SELLER_SUPPORT_SET_EMAIL_OR_PASSWORD = "SELLER_SUPPORT_SET_EMAIL_OR_PASSWORD";

//Profile Common Action Types
export const PROFILE_EDIT_ERROR_LIST = "PROFILE_EDIT_ERROR_LIST";
export const PROFILE_CLEAR_LOCAL_STATE = "PROFILE_CLEAR_LOCAL_STATE";

//Profile -> Change Password
export const PROFILE_CHANGE_PASSWORD_DATA = "PROFILE_CHANGE_PASSWORD_DATA";
export const PROFILE_CLEAR_PASSWORD_DATA = "PROFILE_CLEAR_PASSWORD_DATA";

//Profile -> contact Info
export const PROFILE_EDIT_CONTACT_DETAILS = "PROFILE_EDIT_CONTACT_DETAILS";
export const PROFILE_GET_CONTACT_INITIAL_DATA = "PROFILE_GET_CONTACT_INITIAL_DATA";

//Profile -> Calendar
export const PROFILE_EDIT_OFFICE_TIMINGS = "PROFILE_EDIT_OFFICE_TIMINGS";
export const PROFILE_GET_CALENDER_INITIAL_DATA = "PROFILE_GET_CALENDER_INITIAL_DATA";

//Profile -> Pickup
export const PROFILE_GET_PICKUP_INITIAL_DATA = "PROFILE_GET_PICKUP_INITIAL_DATA";

//Profile -> Business Details
export const PROFILE_SET_IMAGE_SOURCE = "PROFILE_SET_IMAGE_SOURCE";
export const PROFILE_GET_BUSINESS_INITIAL_DATA = "PROFILE_GET_BUSINESS_INITIAL_DATA";

//Profile -> Holiday Info
export const PROFILE_RELOAD_HOLIDAY_DATA = "PROFILE_RELOAD_HOLIDAY_DATA";
export const PROFILE_EDIT_ENTERED_HOLIDAY_LIST = "PROFILE_EDIT_ENTERED_HOLIDAY_LIST";
export const PROFILE_CLEAR_ENTERED_HOLIDAY_LIST = "PROFILE_CLEAR_ENTERED_HOLIDAY_LIST";
export const PROFILE_GET_HOLIDAY_INFO_INITIAL_DATA = "PROFILE_GET_HOLIDAY_INFO_INITIAL_DATA";

//Profile -> Help (Seller Support)
export const PROFILE_SET_SELLER_SUPPORT_CODE = "PROFILE_SET_SELLER_SUPPORT_CODE";

//Reports
export const REPORTS_SET_DATE = "REPORTS_SET_DATE";
export const REPORTS_SET_INVOICE_NO = "REPORTS_SET_INVOICE_NO";
export const REPORTS_GET_INITIAL_DATA = "REPORTS_GET_INITIAL_DATA";
export const REPORTS_CLEAR_LOCAL_STATE = "REPORTS_CLEAR_LOCAL_STATE";

//Analytics -> performance
export const PERFORMANCE_RELOAD_DATA = "PERFORMANCE_RELOAD_DATA";
export const PERFORMANCE_UPDATE_GRAPH = "PERFORMANCE_UPDATE_GRAPH";
export const PERFORMANCE_GET_INITIAL_DATA = "PERFORMANCE_GET_INITIAL_DATA";
export const PERFORMANCE_CLEAR_LOCAL_STATE = "PERFORMANCE_CLEAR_LOCAL_STATE";

//Analytics ->Dashboard
export const DASHBOARD_GET_INITIAL_DATA = "DASHBOARD_GET_INITIAL_DATA";

// Orders -> Orders-Cancel
export const ORDERS_CANCEL_RELOAD_DATA = "ORDERS_CANCEL_RELOAD_DATA";
export const ORDERS_CANCEL_APPEND_DATA = "ORDERS_CANCEL_APPEND_DATA";
export const ORDERS_CANCEL_CHANGE_PROPERTY = "ORDERS_CANCEL_CHANGE_PROPERTY";
export const ORDERS_CANCEL_GET_INITIAL_DATA = "ORDERS_CANCEL_GET_INITIAL_DATA";
export const ORDERS_CANCEL_CLEAR_LOCAL_STATE = "ORDERS_CANCEL_CLEAR_LOCAL_STATE";

// Orders -> Orders-Returns
export const ORDERS_RETURN_SET_SEARCH = "ORDERS_RETURN_SET_SEARCH";
export const ORDERS_RETURN_RELOAD_DATA = "ORDERS_RETURN_RELOAD_DATA";
export const ORDERS_RETURN_APPEND_DATA = "ORDERS_RETURN_APPEND_DATA";
export const ORDERS_RETURN_SET_ITEM_INFO = "ORDERS_RETURN_SET_ITEM_INFO";
export const ORDERS_RETURN_SET_TRACK_INFO = "ORDERS_RETURN_SET_TRACK_INFO";
export const ORDERS_RETURN_CHANGE_PAGE_NO = "ORDERS_RETURN_CHANGE_PAGE_NO";
export const ORDERS_RETURN_SET_ISSUE_INFO = "ORDERS_RETURN_SET_ISSUE_INFO";
export const ORDERS_RETURN_CLEAR_LOCAL_STATE = "ORDERS_RETURN_CLEAR_LOCAL_STATE";
export const ORDERS_RETURN_UPDATE_ISSUE_INFO = "ORDERS_RETURN_UPDATE_ISSUE_INFO";
export const ORDERS_RETURN_GET_INITIAL_STATE = "ORDERS_RETURN_GET_INITIAL_STATE";

//Orders -> Issues
export const ORDERS_ISSUE_APPEND_DATA = "ORDERS_ISSUE_APPEND_DATA";
export const ORDERS_ISSUE_RELOAD_DATA = "ORDERS_ISSUE_RELOAD_DATA";
export const ORDERS_ISSUE_CHANGE_FILTER = "ORDERS_ISSUE_CHANGE_FILTER";
export const ORDERS_ISSUE_SET_ITEM_INFO = "ORDERS_ISSUE_SET_ITEM_INFO";
export const ORDERS_ISSUE_GET_INITIAL_DATA = "ORDERS_ISSUE_GET_INITIAL_DATA";
export const ORDERS_ISSUES_SET_ISSUE_COUNT = "ORDERS_ISSUES_SET_ISSUE_COUNT";
export const ORDERS_ISSUE_CLEAR_LOCAL_STATE = "ORDERS_ISSUE_CLEAR_LOCAL_STATE";

// Orders -> FullFilled
export const ORDERS_FULFILLED_APPEND_DATA = "ORDERS_FULFILLED_APPEND_DATA";
export const ORDERS_FULFILLED_RELOAD_DATA = "ORDERS_FULFILLED_RELOAD_DATA";
export const ORDERS_FULFILLED_CHANGE_SEARCH = "ORDERS_FULFILLED_CHANGE_SEARCH";
export const ORDERS_FULFILLED_SET_ITEM_INFO = "ORDERS_FULFILLED_SET_ITEM_INFO";
export const ORDERS_FULFILLED_CHANGE_PAGE_NO = "ORDERS_FULFILLED_CHANGE_PAGE_NO";
export const ORDERS_FULFILLED_SET_TRACK_INFO = "ORDERS_FULFILLED_SET_TRACK_INFO";
export const ORDERS_FULFILLED_GET_INITIAL_DATA = "ORDERS_FULFILLED_GET_INITIAL_DATA";
export const ORDERS_FULFILLED_CLEAR_LOCAL_STATE = "ORDERS_FULFILLED_CLEAR_LOCAL_STATE";

//Payments
export const PAYMENTS_RELOAD_DATA = "PAYMENTS_RELOAD_DATA";
export const PAYMENTS_CHANGE_DATA = "PAYMENTS_CHANGE_DATA";
export const PAYMENTS_GET_INITIAL_DATA = "PAYMENTS_GET_INITIAL_DATA";
export const PAYMENTS_CLEAR_LOCAL_STATE = "PAYMENTS_CLEAR_LOCAL_STATE";

//Opportunities
export const TOP_SELLING_RELOAD_DATA = "TOP_SELLING_RELOAD_DATA";
export const TOP_SELLING_APPEND_DATA = "TOP_SELLING_APPEND_DATA";
export const OPPORTUNITIES_CLEAR_DATA = "OPPORTUNITIES_CLEAR_DATA";
export const OPPORTUNITIES_APPEND_DATA = "OPPORTUNITIES_APPEND_DATA";
export const OPPORTUNITIES_RELOAD_DATA = "OPPORTUNITIES_RELOAD_DATA";
export const OPPORTUNITIES_CHANGE_SELLER = "OPPORTUNITIES_CHANGE_SELLER";
export const OPPORTUNITIES_CHANGE_SEARCH = "OPPORTUNITIES_CHANGE_SEARCH";
export const OPPORTUNITIES_CHANGE_CATEGORY = "OPPORTUNITIES_CHANGE_CATEGORY";
export const OPPORTUNITIES_GET_INITIAL_DATA = "OPPORTUNITIES_GET_INITIAL_DATA";
export const OPPORTUNITIES_CHANGE_PAGE_SORT = "OPPORTUNITIES_CHANGE_PAGE_SORT";
export const TOP_SELLING_RELOAD_SELLER_DATA = "TOP_SELLING_RELOAD_SELLER_DATA";
export const OPPORTUNITIES_CLEAR_LOCAL_STATE = "OPPORTUNITIES_CLEAR_LOCAL_STATE";
export const COMMISSION_CHANGE_ID_CODE_AND_COMMISSION = "COMMISSION_CHANGE_ID_CODE_AND_COMMISSION";
export const COMMISSION_CHANGE_COMMISSION_AFTER_UPDATE = "COMMISSION_CHANGE_COMMISSION_AFTER_UPDATE";

//Distribution Center
export const DC_SET_DC_ID = "DC_SET_DC_ID";
export const DC_APPEND_DATA = "DC_APPEND_DATA";
export const DC_RELOAD_DATA = "DC_RELOAD_DATA";
export const DC_CHANGE_FILTER = "DC_CHANGE_FILTER";
export const DC_EDIT_ITEM_INFO = "DC_EDIT_ITEM_INFO";
export const DC_GET_INITIAL_DATA = "DC_GET_INITIAL_DATA";
export const DC_CLEAR_LOCAL_STATE = "DC_CLEAR_LOCAL_STATE";
export const DC_SET_SELECTED_AWBS = "DC_SET_SELECTED_AWBS";
export const DC_SET_MANIFEST_ORDER = "DC_SET_MANIFEST_ORDER";
export const DC_SET_SELECTED_ORDER = "DC_SET_SELECTED_ORDER";
export const DC_CLEAR_SELECTED_ITEMS = "DC_CLEAR_SELECTED_ITEMS";
export const DC_SET_BATCH_WISE_ITEMS = "DC_SET_BATCH_WISE_ITEMS";
export const DC_SET_CANCEL_ORDER_NUMBER = "DC_SET_CANCEL_ORDER_NUMBER";
export const DC_SET_NOTE_IN_SELECTED_ORDER = "DC_SET_NOTE_IN_SELECTED_ORDER";
export const DC_CHANGE_ID_IN_SELECTED_ITEMS = "DC_CHANGE_ID_IN_SELECTED_ITEMS";
export const DC_SET_DIMENSIONS_IN_MANIFEST_ORDER = "DC_SET_DIMENSIONS_IN_MANIFEST_ORDER";
export const DC_ADD_DELETE_ITEM_IN_SELECTED_ITEMS = "DC_ADD_DELETE_ITEM_IN_SELECTED_ITEMS";

//Listing
export const LISTING_CHANGE_DATA = "LISTING_CHANGE_DATA";
export const LISTING_SELECT_ITEM = "LISTING_SELECT_ITEM";
export const LISTING_RELOAD_DATA = "LISTING_RELOAD_DATA";
export const LISTING_APPEND_DATA = "LISTING_APPEND_DATA";
export const LISTING_CHANGE_DC_ID = "LISTING_CHANGE_DC_ID";
export const LISTING_CHANGE_SEARCH = "LISTING_CHANGE_SEARCH";
export const LISTING_GET_INITIAL_DATA = "LISTING_GET_INITIAL_DATA";
export const LISTING_CHANGE_PAGE_SORT = "LISTING_CHANGE_PAGE_SORT";
export const LISTING_CHANGE_PAGE_TYPE = "LISTING_CHANGE_PAGE_TYPE";
export const LISTING_CHANGE_PAGE_SIZE = "LISTING_CHANGE_PAGE_SIZE";
export const LISTING_CLEAR_LOCAL_STATE = "LISTING_CLEAR_LOCAL_STATE";
export const LISTING_SET_PRICE_HISTORY = "LISTING_SET_PRICE_HISTORY";
export const LISTING_CHANGE_CURRENT_PAGE = "LISTING_CHANGE_CURRENT_PAGE";
export const LISTING_CHANGE_STOCK_FILTER = "LISTING_CHANGE_STOCK_FILTER";
export const LISTING_SET_MAX_AND_MIN_STOCK = "LISTING_SET_MAX_AND_MIN_STOCK";
export const LISTING_SET_DATA_AFTER_UPDATE = "LISTING_SET_DATA_AFTER_UPDATE";
export const LISTING_SET_ID_CODE_AND_STOCK = "LISTING_SET_ID_CODE_AND_STOCK";
export const LISTING_CHANGE_ITEMLIST_AFTER_QUANTITY_UPDATE = "LISTING_CHANGE_ITEMLIST_AFTER_QUANTITY_UPDATE";

// Add Listing
export const ADD_LISTING_RELOAD_DATA = "ADD_LISTING_RELOAD_DATA";
export const ADD_LISTING_APPEND_DATA = "ADD_LISTING_APPEND_DATA";
export const ADD_LISTING_CHANGE_SEARCH = "ADD_LISTING_CHANGE_SEARCH";
export const ADD_LISTING_CHANGE_SORT_BY = "ADD_LISTING_CHANGE_SORT_BY";
export const ADD_LISTING_CHANGE_CATEGORY = "ADD_LISTING_CHANGE_CATEGORY";
export const ADD_LISTING_GET_INITIAL_DATA = "ADD_LISTING_GET_INITIAL_DATA";
export const ADD_LISTING_CLEAR_LOCAL_STATE = "ADD_LISTING_CLEAR_LOCAL_STATE";
export const ADD_LISTING_CLEAR_SELECTED_ITEMS = "ADD_LISTING_CLEAR_SELECTED_ITEMS";
export const ADD_LISTING_ADD_IN_SELECTED_ITEMS = "ADD_LISTING_ADD_IN_SELECTED_ITEMS";
export const ADD_LISTING_ADD_DELETE_ITEM_IN_SELECTED_ITEMS = "ADD_LISTING_ADD_DELETE_ITEM_IN_SELECTED_ITEMS";

//Orders
export const ORDERS_ITEM_INFO = "ORDERS_ITEM_INFO";
export const ORDERS_RESET_DATA = "ORDERS_RESET_DATA";
export const ORDERS_ITEM_CHANGE = "ORDERS_ITEM_CHANGE";
export const ORDERS_APPEND_DATA = "ORDERS_APPEND_DATA";
export const ORDERS_RELOAD_DATA = "ORDERS_RELOAD_DATA";
export const ORDERS_ORDER_CHANGE = "ORDERS_ORDER_CHANGE";
export const ORDERS_AWB_CHECK_ALL = "ORDERS_AWB_CHECK_ALL";
export const ORDERS_SCAN_AWB_ITEM = "ORDERS_SCAN_AWB_ITEM";
export const ORDERS_CHANGE_NEW_MRP = "ORDERS_CHANGE_NEW_MRP";
export const ORDERS_REMOVE_PACKETS = "ORDERS_REMOVE_PACKETS";
export const ORDERS_MANIFEST_ORDER = "ORDERS_MANIFEST_ORDER";
export const ORDERS_CHANGE_BOX_SIZE = "ORDERS_CHANGE_BOX_SIZE";
export const ORDERS_AWB_ITEM_CHANGE = "ORDERS_AWB_ITEM_CHANGE";
export const ORDERS_GET_INITIAL_DATA = "ORDERS_GET_INITIAL_DATA";
export const ORDERS_CHECK_ALL_ORDERS = "ORDERS_CHECK_ALL_ORDERS";
export const ORDERS_CHANGE_PAGE_TYPE = "ORDERS_CHANGE_PAGE_TYPE";
export const ORDERS_PACKET_CHECK_ALL = "ORDERS_PACKET_CHECK_ALL";
export const ORDERS_CONFIRM_EDIT_MRP = "ORDERS_CONFIRM_EDIT_MRP";
export const ORDERS_SCAN_PACKET_ITEM = "ORDERS_SCAN_PACKET_ITEM";
export const ORDERS_CLEAR_LOCAL_STATE = "ORDERS_CLEAR_LOCAL_STATE";
export const ORDERS_CHANGE_PROPERTIES = "ORDERS_CHANGE_PROPERTIES";
export const ORDERS_SET_MANIFEST_TYPE = "ORDERS_SET_MANIFEST_TYPE";
export const ORDERS_SET_FILE_DIMENSION = "ORDERS_SET_FILE_DIMENSION";
export const ORDERS_CONFIRM_UPDATE_MRP = "ORDERS_CONFIRM_UPDATE_MRP";
export const ORDERS_PACKET_ITEM_CHANGE = "ORDERS_PACKET_ITEM_CHANGE";
export const ORDERS_PRESCRIPTION_ITEMS = "ORDERS_PRESCRIPTION_ITEMS";
export const ORDERS_SET_PARCEL_DIMENSION = "ORDERS_SET_PARCEL_DIMENSION";
export const ORDERS_ADD_PRESCRIPTION_ITEM = "ORDERS_ADD_PRESCRIPTION_ITEM";
export const ORDERS_SET_PRESCRIPTION_DATA = "ORDERS_SET_PRESCRIPTION_DATA";
export const ORDERS_CANCEL_QUANTITY_CHANGE = "ORDERS_CANCEL_QUANTITY_CHANGE";
export const ORDERS_DELETE_PRESCRIPTION_ITEM = "ORDERS_DELETE_PRESCRIPTION_ITEM";
export const ORDERS_SET_NON_MANIFESTED_ORDERS = "ORDERS_SET_NON_MANIFESTED_ORDERS";
export const ORDERS_CHANGE_ORDER_ITEM_ID_CODE = "ORDERS_CHANGE_ORDER_ITEM_ID_CODE";

// common data page
export const COMMON_CLEAR_STORE_DATA = "COMMON_CLEAR_STORE_DATA";
export const COMMON_GET_WEBSITES_DATA = "COMMON_GET_WEBSITES_DATA";
