import {
	ORDERS_GET_INITIAL_DATA,
	ORDERS_CHANGE_BOX_SIZE,
	ORDERS_SET_FILE_DIMENSION,
	ORDERS_SET_NON_MANIFESTED_ORDERS,
	ORDERS_SET_PRESCRIPTION_DATA,
	ORDERS_ADD_PRESCRIPTION_ITEM,
	ORDERS_DELETE_PRESCRIPTION_ITEM,
	ORDERS_SCAN_PACKET_ITEM,
	ORDERS_REMOVE_PACKETS,
	ORDERS_AWB_ITEM_CHANGE,
	ORDERS_ITEM_INFO,
	ORDERS_PRESCRIPTION_ITEMS,
	ORDERS_SET_PARCEL_DIMENSION,
	ORDERS_SCAN_AWB_ITEM,
	ORDERS_PACKET_ITEM_CHANGE,
	ORDERS_CANCEL_QUANTITY_CHANGE,
	ORDERS_RESET_DATA,
	ORDERS_CHANGE_NEW_MRP,
	ORDERS_ORDER_CHANGE,
	ORDERS_CHECK_ALL_ORDERS,
	ORDERS_ITEM_CHANGE,
	ORDERS_CONFIRM_UPDATE_MRP,
	ORDERS_CONFIRM_EDIT_MRP,
	ORDERS_CHANGE_ORDER_ITEM_ID_CODE,
	ORDERS_CHANGE_PROPERTIES,
	ORDERS_APPEND_DATA,
	ORDERS_PACKET_CHECK_ALL,
	ORDERS_AWB_CHECK_ALL,
	ORDERS_SET_MANIFEST_TYPE,
	ORDERS_RELOAD_DATA,
	ORDERS_CHANGE_PAGE_TYPE,
	ORDERS_MANIFEST_ORDER,
	ORDERS_CLEAR_LOCAL_STATE
} from "~/actions/action-types";

import { ProjectUrl } from "~/env";

import UIkit from "uikit";

export const OrdersResetData = () => (dispatch, getState) => {
	if (getState().user_info_store.shipment_model === 3) {
		dispatch({ type: ORDERS_RESET_DATA });
	}
};
export const OrdersGetInitialData = (hub) => (dispatch, getState) => {
	dispatch({ type: ORDERS_CLEAR_LOCAL_STATE });
	return fetch(ProjectUrl + "/orders/getdata", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({
			pagetype: "new",
			hub: hub,
			pagesize: 100,
			pageno: 1,
			sortby: "high_priority desc,dispatch_by",
			search: "",
			filter: ""
		})
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: ORDERS_GET_INITIAL_DATA, data: data, mode: "data", hub: hub });
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

const OrdersGetData = (page_type, hub, page_size, page_no, sort_by, search, filter, website_filter) => (dispatch) => {
	let dataset = {
		pagetype: page_type,
		hub: hub,
		pagesize: page_size,
		pageno: page_no,
		sortby: sort_by,
		search: search,
		website_filter: website_filter
	};
	if (filter != undefined) {
		dataset = { ...dataset, filter: filter };
	}

	nanobar.go(50);
	fetch(ProjectUrl + "/orders/getdata", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify(dataset)
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: ORDERS_RELOAD_DATA, data: data });
			nanobar.go(100);
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

// Header
export const OrdersPageTypeChange = (page_type) => (dispatch, getState) => {
	const hub = getState().orders_store.hub;
	if (hub == "true" && page_type == "upcoming") {
		dispatch({ type: ORDERS_CHANGE_PROPERTIES, name: "filter", value: "" });
	}

	const page_size = getState().orders_store.datalist.pagesize;
	const sort_by = getState().orders_store.datalist.sortby;
	const search = getState().orders_store.search;
	const filter = getState().orders_store.filter;
	const website_filter = getState().orders_store.website_filter;
	dispatch(OrdersGetData(page_type, hub, page_size, 1, sort_by, search, filter, website_filter));
};

export const OrdersPageFilterChange = (value) => (dispatch, getState) => {
	dispatch({ type: ORDERS_CHANGE_PROPERTIES, name: "filter", value: value });

	const hub = getState().orders_store.hub;
	const search = getState().orders_store.search;
	const sort_by = getState().orders_store.datalist.sortby;
	const page_type = getState().orders_store.datalist.pagetype;
	const page_size = getState().orders_store.datalist.pagesize;
	const website_filter = getState().orders_store.website_filter;
	dispatch(OrdersGetData(page_type, hub, page_size, 1, sort_by, search, value, website_filter));
};

export const OrdersWebsiteFilterChange = (value) => (dispatch, getState) => {
	dispatch({ type: ORDERS_CHANGE_PROPERTIES, name: "website_filter", value: value });

	const hub = getState().orders_store.hub;
	const search = getState().orders_store.search;
	const sort_by = getState().orders_store.datalist.sortby;
	const page_type = getState().orders_store.datalist.pagetype;
	const page_size = getState().orders_store.datalist.pagesize;
	const filter = getState().orders_store.filter;
	dispatch(OrdersGetData(page_type, hub, page_size, 1, sort_by, search, filter, value));
};

export const OrdersOpenModal = (modal) => (dispatch) => {
	if (modal == "#modalManifest") {
		dispatch({ type: ORDERS_SET_MANIFEST_TYPE, value: "orders" });
	}
	UIkit.modal(modal).show();
};

export const OrdersSearchChange = (value) => (dispatch) => {
	dispatch({ type: ORDERS_CHANGE_PROPERTIES, name: "search", value: value });
};

export const OrdersPressEnterCheck = (value, char_code) => (dispatch, getState) => {
	if (char_code == 13) {
		const page_size = getState().orders_store.datalist.pagesize;
		const page_type = getState().orders_store.datalist.pagetype;
		const sort_by = getState().orders_store.datalist.sortby;
		const filter = getState().orders_store.filter;
		const hub = getState().orders_store.hub;
		const website_filter = getState().orders_store.website_filter;
		if (hub == "true") {
			dispatch(OrdersGetData(page_type, hub, page_size, 1, sort_by, value));
		} else if (hub == "false") {
			dispatch(OrdersGetData(page_type, hub, page_size, 1, sort_by, value, filter, website_filter));
		}
	}
};

export const OrdersHeaderPrintSlips = (type) => (dispatch, getState) => {
	const hub = getState().orders_store.hub;
	if (hub == "true") {
		var action = "packetinvoiceslip";
		var awb_value = getState().orders_store.packet_info;
		var hub_parameter = '<input type="hidden" name="hub" value="true">';
		if (type == "address") {
			action = "awbaddressslip";
			awb_value = getState().orders_store.awb_selected_items;
		}
		$(
			'<form method="post" action="/reports/' +
				action +
				'" target="_blank">' +
				'<input type="hidden" name="idlist" value="' +
				awb_value +
				'">' +
				hub_parameter +
				"</form>"
		)
			.appendTo("body")
			.submit()
			.remove();
	} else if (hub == "false") {
		const awb_selected_items = getState().orders_store.awb_selected_items;
		const action = type;
		$(
			'<form method="post" action="/reports/' +
				action +
				'" target="_blank">' +
				'<input type="hidden" name="idlist" value="' +
				awb_selected_items +
				'">' +
				"</form>"
		)
			.appendTo("body")
			.submit()
			.remove();
	}
};

export const OrdersPrintManifestReport = () => (dispatch, getState) => {
	const awb_selected_items = getState().orders_store.awb_selected_items;
	const hub = getState().orders_store.hub;

	$(
		'<form method="post" action="/reports/manifestreport" target="_blank">' +
			`<input type="hidden" name="hub" value=${hub}>` +
			'<input type="hidden" name="idlist" value="' +
			awb_selected_items +
			'">' +
			"</form>"
	)
		.appendTo("body")
		.submit()
		.remove();
};

//Awb Card
export const OrdersAwbItemSelect = (id) => (dispatch) => {
	dispatch({ type: ORDERS_AWB_ITEM_CHANGE, data: id });
};

export const OrdersAwbTickFunction = (id) => (dispatch, getState) => {
	const awb_selected_items = getState().orders_store.awb_selected_items;
	if (awb_selected_items.indexOf(id) == -1) {
		return false;
	}
	return true;
};

export const OrdersTrackItemList = (page_type, awb) => (dispatch) => {
	nanobar.go(50);
	fetch(ProjectUrl + "/orders/trackitemlist", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ pagetype: page_type, id: awb })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: ORDERS_ITEM_INFO, data: data });
			nanobar.go(100);
			UIkit.modal("#modalTrackItemList").show();
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

//hub orders Awb page
export const OrdersPageNoChange = () => (dispatch, getState) => {
	const page_type = getState().orders_store.datalist.pagetype;
	const page_size = getState().orders_store.datalist.pagesize;
	const page_no = getState().orders_store.datalist.pageno;
	const sort_by = getState().orders_store.datalist.sortby;
	const search = getState().orders_store.search;
	const hub = getState().orders_store.hub;
	return OrdersAppendGetData(page_type, hub, page_size, page_no + 1, sort_by, search)(dispatch);
};

export const OrdersAwbCheckAll = (type) => (dispatch, getState) => {
	const hub = getState().orders_store.hub;
	if (hub == "true") {
		if (type == 1) {
			dispatch({ type: ORDERS_AWB_CHECK_ALL });
		} else {
			dispatch({ type: ORDERS_PACKET_CHECK_ALL });
		}
	} else if (hub == "false") {
		dispatch({ type: ORDERS_AWB_CHECK_ALL });
	}
};

export const OrdersTickAll = (type) => (dispatch, getState) => {
	const awb_selected_items = getState().orders_store.awb_selected_items;
	const courier_itemlist = getState().orders_store.datalist.courier_itemlist;
	const packet_info = getState().orders_store.packet_info;
	const order_list = getState().orders_store.datalist.orderlist;
	const hub = getState().orders_store.hub;
	if (hub == "true") {
		if (type == 1) {
			if (awb_selected_items.length == courier_itemlist.length) {
				return true;
			}
			return false;
		} else {
			if (packet_info.length == order_list.length) {
				return true;
			}
			return false;
		}
	} else if (hub == "false") {
		if (awb_selected_items.length == courier_itemlist.length) {
			return true;
		}
		return false;
	}
};

const OrdersAppendGetData = (page_type, hub, page_size, page_no, sort_by, search) => (dispatch) => {
	nanobar.go(50);
	return fetch(ProjectUrl + "/orders/getdata", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ pagetype: page_type, hub: hub, pagesize: page_size, pageno: page_no, sortby: sort_by, search: search })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: ORDERS_APPEND_DATA, data: data });
			nanobar.go(100);
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

//Body
export const OrdersCardItemSelect = (item) => (dispatch) => {
	dispatch({ type: ORDERS_ITEM_CHANGE, data: item });
};

export const OrdersClickOrder = (id) => (dispatch) => {
	dispatch({ type: ORDERS_ORDER_CHANGE, data: id });
};

export const OrdersManifestItems = (order) => (dispatch) => {
	dispatch({ type: ORDERS_MANIFEST_ORDER, data: order });
};

export const OrdersBodyTickFunction = (id) => (dispatch, getState) => {
	const selected_orders = getState().orders_store.selected_orders;
	if (selected_orders.indexOf(id) == -1) {
		return false;
	}
	return true;
};
export const OrdersCardTickFunction = (id) => (dispatch, getState) => {
	const selected_items = getState().orders_store.selected_items;
	if (selected_items.indexOf(id) == -1) {
		return false;
	}
	return true;
};

export const OrdersCancelOptionValues = (qty) => (dispatch) => {
	var options = [];
	for (var i = 1; i <= qty; i++) {
		options.push(
			<option value={i} key={i}>
				{i}
			</option>
		);
	}
	return options;
};

export const OrdersCancelQtyChange = (order_item_id, value) => (dispatch) => {
	dispatch({ type: ORDERS_CANCEL_QUANTITY_CHANGE, id: order_item_id, qty: parseInt(value) });
};

export const OrdersCancelOptionSelected = (order_item_id) => (dispatch, getState) => {
	const cancel_items = getState().orders_store.cancel_items;
	return cancel_items.filter((item) => item.order_item_id == order_item_id)[0].qty;
};

export const OrdersEditMrp = (id_code) => (dispatch, getState) => {
	const order_item_id_code = getState().orders_store.order_item_id_code;
	if (order_item_id_code == id_code) {
		dispatch({ type: ORDERS_CHANGE_ORDER_ITEM_ID_CODE, id_code: "" });
	} else {
		UIkit.modal("#modalMrpEdit").show();
		dispatch({ type: ORDERS_CHANGE_ORDER_ITEM_ID_CODE, id_code: id_code });
	}
};

export const OrdersChangeMrp = (value) => (dispatch) => {
	dispatch({ type: ORDERS_CHANGE_NEW_MRP, new_mrp: value });
};

export const OrdersCheckMrp = () => (dispatch, getState) => {
	let old_mrp = 0;
	let order_item_id_code = getState().orders_store.order_item_id_code;
	const order_list = getState().orders_store.datalist.orderlist;
	order_list.find((order) =>
		order.itemlist.find((item) => {
			if (item.order_item_id == order_item_id_code) {
				old_mrp = item.mrp;
				return true;
			}
		})
	);
	const new_mrp = getState().orders_store.new_mrp;
	if (new_mrp == "") {
		alertify.error("MRP cannot be empty");
	} else if (parseInt(new_mrp) >= old_mrp) {
		alertify.error("New MRP cannot be greater than the old MRP");
	} else if (parseInt(new_mrp) < old_mrp * 0.7) {
		alertify.error("New MRP cannot be less than Rs. " + Math.floor(old_mrp * 0.7));
	} else {
		UIkit.modal("#modalMrpUpdate").show();
	}
};

export const OrdersValue = (order) => (dispatch) => {
	var order_value = 0;
	for (var i = 0; i < order.itemlist.length; i++) {
		order_value += order.itemlist[i].total;
	}
	return order_value;
};

export const OrdersQty = (order) => (dispatch) => {
	var order_qty = 0;
	for (var i = 0; i < order.itemlist.length; i++) {
		order_qty += order.itemlist[i].qty;
	}
	return order_qty;
};

export const OrdersBodyOpenModal = (modal) => (dispatch) => {
	if (modal == "#modalManifest") {
		dispatch({ type: ORDERS_SET_MANIFEST_TYPE, value: "items" });
	}
	UIkit.modal(modal).show();
};

export const OrdersCardClass = (dispatch_by) => (dispatch, getState) => {
	var classes = false;
	const page_type = getState().orders_store.datalist.pagetype;
	if (page_type == "new") {
		var time = dispatch_by.split(" ")[1];
		var date = dispatch_by.split(" ")[0];
		var iso_date_parts = date.split("/");
		var iso_date = iso_date_parts[2] + "/" + iso_date_parts[1] + "/" + iso_date_parts[0] + " " + time;
		if (Date.parse(iso_date) < Date.now()) {
			classes = true;
		}
	}
	return classes;
};

export const OrdersBodyCheckAll = () => (dispatch, getState) => {
	const selected_orders = getState().orders_store.selected_orders;
	if (selected_orders.length == 0) {
		UIkit.modal("#modalCheckallOrders").show();
	} else {
		dispatch({ type: ORDERS_CHECK_ALL_ORDERS });
	}
};

export const OrdersBodyTickAll = () => (dispatch, getState) => {
	const selected_orders = getState().orders_store.selected_orders;
	const order_list = getState().orders_store.datalist.orderlist;

	if (selected_orders.length == order_list.length) {
		return true;
	}
	return false;
};

export const HubOrdersCancelIssue = () => (dispatch) => {
	UIkit.modal("#modalCancelIssue").show();
};

//Update Mrp Modal
export const OrdersUpdateMrp = (mrp_update_confirmation) => (dispatch, getState) => {
	dispatch({ type: ORDERS_CONFIRM_UPDATE_MRP, mrp_update_confirmation: mrp_update_confirmation });
	if (mrp_update_confirmation == true) {
		return OrdersSaveMrp()(dispatch, getState);
	} else {
		UIkit.modal("#modalMrpUpdate").hide();
		return Promise.resolve();
	}
};

const OrdersSaveMrp = () => (dispatch, getState) => {
	const order_item_id_code = getState().orders_store.order_item_id_code;
	const new_mrp = getState().orders_store.new_mrp;
	const hub = getState().orders_store.hub;
	const dataset = {
		order_item_idcode: order_item_id_code,
		mrp: parseInt(new_mrp),
		hub: String(hub) == "true"
	};
	return fetch(ProjectUrl + "/orders/updateitemmrp", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify(dataset)
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			alertify.success("MRP Saved");
			dispatch({ type: ORDERS_RELOAD_DATA, data: data });
			UIkit.modal("#modalMrpUpdate").hide();
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

//Edit Mrp Modal
export const OrdersModalEditMrp = (mrp_edit_confirmation) => (dispatch) => {
	dispatch({ type: ORDERS_CONFIRM_EDIT_MRP, mrp_edit_confirmation: mrp_edit_confirmation });
	UIkit.modal("#modalMrpEdit").hide();
};

// Batch Expiry Modal
export const OrdersOpenManifestModal = () => (dispatch) => {
	UIkit.modal("#modalManifest").show();
};

export const OrdersDataChange = (variant_id, id, name, value) => (dispatch) => {
	let val = value;
	if (name == "input_qty" && val != "") {
		val = parseInt(value);
	}
	dispatch({ type: ORDERS_SET_PRESCRIPTION_DATA, id: id, name: name, value: val, variant_id: variant_id });
};

export const OrdersCheckDate = (expiry_date) => (dispatch) => {
	var d1_date = new Date();
	var new_date = new Date(d1_date.setMonth(d1_date.getMonth() + 6));
	if (new Date(expiry_date) < new_date) return false;
	return true;
};

const OrdersCheckPrescriptionItems = () => (dispatch, getState) => {
	const prescription_data = getState().orders_store.prescription_data;
	for (var i = 0; i < prescription_data.length; i++) {
		let prescription_item = prescription_data[i];
		var total_input_qty = prescription_item.batch_items.reduce(function (a, b) {
			return a + b.input_qty;
		}, 0);
		if (prescription_item.qty != total_input_qty) {
			alertify.error("Quantity does not match for " + prescription_item.variant_name);
			return false;
		}

		for (var j = 0; j < prescription_item.batch_items.length; j++) {
			let item = prescription_item.batch_items[j];
			if (
				prescription_item.batch_items.some(function (batch_item) {
					return batch_item.id != item.id && batch_item.batch == item.batch && batch_item.expiry == item.expiry;
				})
			) {
				alertify.error("Batch & Expiry cannot be same for " + prescription_item.variant_name);
				return false;
			}
			if (item.input_qty == "" || parseInt(item.input_qty) <= 0) {
				alertify.error("Quantity required for " + prescription_item.variant_name);
				return false;
			}
			if (prescription_item.prescription && (item.batch == "" || item.expiry == "")) {
				alertify.error("Batch and Expiry required for " + prescription_item.variant_name);
				return false;
			}
			if (item.expiry != "" && !OrdersCheckDate(item.expiry)) {
				alertify.error("Expiry cannot be less than 6 months from now for " + prescription_item.variant_name);
				return false;
			}
			if (prescription_item.batch_items.length > 1 && item.expiry == "" && item.batch == "") {
				alertify.error("Both Batch & Expiry cannot be empty for " + prescription_item.variant_name);
				return false;
			}
		}
	}
	return true;
};

export const OrdersBatchCreateManifest = () => (dispatch, getState) => {
	if (dispatch(OrdersCheckPrescriptionItems())) {
		var id_list = [];
		const hub = getState().orders_store.hub;
		const manifest_type = getState().orders_store.manifest_type;
		const selected_items = getState().orders_store.selected_items;
		const selected_orders = getState().orders_store.selected_orders;
		if (manifest_type == "items") {
			id_list = selected_items;
		} else if (manifest_type == "orders") {
			id_list = selected_orders;
		}
		if (id_list.length > 0) {
			const prescription_data = getState().orders_store.prescription_data;
			return fetch(ProjectUrl + "/orders/createmanifest", {
				method: "POST",
				credentials: "include",
				headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
				body: JSON.stringify({
					idlist: id_list,
					type: manifest_type,
					hub: String(hub) == "true",
					prescription_data: JSON.stringify(prescription_data)
				})
			})
				.then((res) => (res.ok ? res.json() : Promise.reject(res)))
				.then((data) => {
					if (data.manifested_items) {
						$(
							'<form method="post" action="/reports/invoiceslip" target="_blank">' +
								'<input type="hidden" name="idlist" value="' +
								data.manifested_items +
								'">' +
								`<input type="hidden" name="hub" value=${hub}>` +
								"</form>"
						)
							.appendTo("body")
							.submit()
							.remove();
					}
					dispatch({ type: ORDERS_RELOAD_DATA, data: data.datalist });
					if (data.non_manifested_orders != "") {
						alert(data.non_manifested_orders);
					}
					UIkit.modal("#modalBatchExpiry").hide();
				})
				.catch((error) => {
					if (error instanceof Error) alertify.error(error);
					else error.text().then((data) => alertify.error(data));
				});
		} else {
			return Promise.resolve();
		}
	} else {
		return Promise.resolve();
	}
};

export const OrdersAddPrescriptionItem = (item) => (dispatch) => {
	dispatch({ type: ORDERS_ADD_PRESCRIPTION_ITEM, item: item });
};

export const OrdersDeletePrescriptionItem = (batch_id, variant_id) => (dispatch) => {
	dispatch({ type: ORDERS_DELETE_PRESCRIPTION_ITEM, batch_id: batch_id, variant_id: variant_id });
};

//De Manifest Packet Modal
export const HubOrdersDeManifestPackets = () => (dispatch, getState) => {
	nanobar.go(50);
	const packet_info = getState().orders_store.packet_info;
	return fetch(ProjectUrl + "/orders/demanifestpackets", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ packets: packet_info })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: ORDERS_RELOAD_DATA, data: data });
			UIkit.modal("#modalDemanifest").hide();
			nanobar.go(100);
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

//Orders DeManifest Packets Modal
export const OrdersDeManifestPackets = () => (dispatch, getState) => {
	nanobar.go(50);
	const awb_selected_items = getState().orders_store.awb_selected_items;
	return fetch(ProjectUrl + "/orders/demanifest", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ awbs: awb_selected_items })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: ORDERS_RELOAD_DATA, data: data });
			UIkit.modal("#modalDemanifestOrder").hide();
			nanobar.go(100);
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

//Cancel Modal
export const OrdersCancelItems = (cancel_reason) => (dispatch, getState) => {
	const cancel_items = getState().orders_store.cancel_items;
	if (cancel_reason == "select") {
		alertify.error("Select a valid cancellation reason");
		return Promise.resolve();
	} else {
		const hub = getState().orders_store.hub;
		const search = getState().orders_store.search;
		const sort_by = getState().orders_store.datalist.sortby;
		const page_no = getState().orders_store.datalist.pageno;
		const page_type = getState().orders_store.datalist.pagetype;
		const page_size = getState().orders_store.datalist.pagesize;
		let data_set = {
			idlist: JSON.stringify(cancel_items),
			reason_id: cancel_reason
		};
		if (hub == "true") {
			data_set = { ...data_set, hub: String(hub) == "true" };
		}
		return fetch(ProjectUrl + "/orders/cancelitems", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify(data_set)
		})
			.then((res) => (res.ok ? res.text() : Promise.reject(res)))
			.then(() => {
				UIkit.modal("#modalCancel").hide();
				dispatch(OrdersGetData(page_type, hub, page_size, page_no, sort_by, search));
				alertify.success("Cancellation Successful");
			})
			.catch((error) => {
				if (error instanceof Error) alertify.error(error);
				else error.text().then((data) => alertify.error(data));
			});
	}
};

//unpack
export const HubOrdersDeManifestAWBs = () => (dispatch, getState) => {
	const awb_selected_items = getState().orders_store.awb_selected_items;
	nanobar.go(50);
	return fetch(ProjectUrl + "/orders/demanifest", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ awbs: awb_selected_items, hub: true })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: ORDERS_RELOAD_DATA, data: data });
			UIkit.modal("#modalUnpack").hide();
			nanobar.go(100);
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

//ScanPacket Modal
export const HubOrdersDispatchPackets = () => (dispatch, getState) => {
	nanobar.go(50);
	const packet_info = getState().orders_store.packet_info;
	return fetch(ProjectUrl + "/orders/dispatchpackets", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ packets: packet_info })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			$(
				'<form method="post" action="/reports/packetaddressslip?hub=true" target="_blank">' +
					'<input type="hidden" name="idlist" value="' +
					packet_info +
					'">' +
					"</form>"
			)
				.appendTo("body")
				.submit()
				.remove();
			dispatch({ type: ORDERS_RELOAD_DATA, data: data });
			nanobar.go(100);
			UIkit.modal("#scanPacketModal").hide();
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const HubOrdersScanPressEnterCheck = (char_code, value) => (dispatch, getState) => {
	if (char_code == 13) {
		var packet_no = value;
		var input_field = document.getElementById("scan_packet");
		if (packet_no == "") {
			alertify.error("Packetno cannot be empty");
		} else {
			const packet_info = getState().orders_store.packet_info;
			const order_list = getState().orders_store.datalist.orderlist;
			if (packet_info.indexOf(packet_no) != -1) {
				alertify.error("Packet already exists");
			} else if (order_list.filter((item) => item.packet_id == packet_no).length == 0) {
				dispatch({ type: ORDERS_SCAN_PACKET_ITEM, data: packet_no });
				alertify.error("Packet not found");
			} else {
				dispatch({ type: ORDERS_SCAN_PACKET_ITEM, data: packet_no });
			}
			input_field.value = "";
		}
	}
};

export const HubOrdersRemovePackets = (packet_no, rem_from) => (dispatch) => {
	dispatch({ type: ORDERS_REMOVE_PACKETS, data: packet_no, rem_from: rem_from });
};

//Packet Card
export const HubOrdersPacketItemSelect = (packet_id) => (dispatch) => {
	dispatch({ type: ORDERS_PACKET_ITEM_CHANGE, data: packet_id });
};

export const HubOrdersPacketTickFunction = (id) => (dispatch, getState) => {
	const packet_info = getState().orders_store.packet_info;
	if (packet_info.indexOf(id) == -1) {
		return false;
	}
	return true;
};

export const HubOrdersPacketTrackItemList = (page_type, packet_id) => (dispatch) => {
	nanobar.go(50);
	fetch(ProjectUrl + "/orders/trackitemlist", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ pagetype: page_type, id: packet_id })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: ORDERS_ITEM_INFO, data: data });
			nanobar.go(100);
			UIkit.modal("#modalTrackItemList").show();
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

//HubOrders Manifest Modal
export const HubOrdersCreateManifest = () => (dispatch, getState) => {
	var idlist = [];
	const manifest_type = getState().orders_store.manifest_type;
	const selected_items = getState().orders_store.selected_items;
	const selected_orders = getState().orders_store.selected_orders;
	if (manifest_type == "items") {
		idlist = selected_items;
	} else if (manifest_type == "orders") {
		idlist = selected_orders;
	}
	if (idlist.length > 0) {
		const prescription_data = getState().orders_store.prescription_data;
		return fetch(ProjectUrl + "/orders/createmanifest", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ idlist: idlist, type: manifest_type, hub: true, prescription_data: JSON.stringify(prescription_data) })
		})
			.then((res) => (res.ok ? res.json() : Promise.reject(res)))
			.then((data) => {
				UIkit.modal("#modalManifest").hide();
				$(
					'<form method="post" action="/reports/invoiceslip" target="_blank">' +
						'<input type="hidden" name="idlist" value="' +
						data.manifested_items +
						'">' +
						'<input type="hidden" name="hub" value="true">' +
						"</form>"
				)
					.appendTo("body")
					.submit()
					.remove();

				dispatch({ type: ORDERS_RELOAD_DATA, data: data.datalist });
				if (data.non_manifested_orders != "") {
					alert(data.non_manifested_orders);
				}
			})
			.catch((error) => {
				if (error instanceof Error) alertify.error(error);
				else error.text().then((data) => alertify.error(data));
			});
	} else {
		return Promise.resolve();
	}
};

export const HubOrdersGetPrescriptionItem = () => (dispatch, getState) => {
	var prescription = false;
	const selected_orders = getState().orders_store.selected_orders;
	const orders_list = getState().orders_store.datalist.orderlist;
	for (var i = 0; i < selected_orders.length; i++) {
		var selected_order = selected_orders[i];
		var order = orders_list.filter((order) => order.order_id == selected_order);
		if (order.length > 0) {
			prescription = order[0].itemlist.some((item) => item.prescription_required == true);
			if (prescription) break;
		}
	}
	return prescription;
};

export const OrdersBatchExpiryModal = () => (dispatch) => {
	dispatch({ type: ORDERS_PRESCRIPTION_ITEMS });
	UIkit.modal("#modalBatchExpiry").show();
};

//Orders Manifest Modal
export const OrdersCreateManifest = (type) => (dispatch, getState) => {
	var idlist = [];
	const manifest_type = getState().orders_store.manifest_type;
	const selected_items = getState().orders_store.selected_items;
	const selected_orders = getState().orders_store.selected_orders;
	if (manifest_type == "items") {
		idlist = selected_items;
	} else if (manifest_type == "orders") {
		idlist = selected_orders;
	}
	if (idlist.length > 0) {
		return fetch(ProjectUrl + "/orders/createmanifest", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ idlist: idlist, type: manifest_type, hub: false, prescription_data: "[]" })
		})
			.then((res) => (res.ok ? res.json() : Promise.reject(res)))
			.then((data) => {
				UIkit.modal("#modalManifest").hide();
				if (data.manifested_items != "") {
					var action = "combinedslip";
					if (type == "separate") {
						action = "invoiceslip";
						var abc = Math.random();
						window.open("", abc);
						$(
							'<form method="post" action="/reports/addressslip" target="' +
								abc +
								'">' +
								'<input type="hidden" name="idlist" value="' +
								data.manifested_items +
								'">' +
								"</form>"
						)
							.appendTo("body")
							.submit()
							.remove();
					}
					$(
						'<form method="post" action="/reports/' +
							action +
							'" target="_blank">' +
							'<input type="hidden" name="idlist" value="' +
							data.manifested_items +
							'">' +
							"</form>"
					)
						.appendTo("body")
						.submit()
						.remove();

					dispatch({ type: ORDERS_RELOAD_DATA, data: data.datalist });
				}
				if (data.non_manifested_orders != "") {
					dispatch({ type: ORDERS_SET_NON_MANIFESTED_ORDERS, data: data.non_manifested_orders });
					UIkit.modal("#modalNonManifest").show();
				}
			})
			.catch((error) => {
				if (error instanceof Error) alertify.error(error);
				else error.text().then((data) => alertify.error(data));
			});
	} else {
		return Promise.resolve();
	}
};

//Check all orders modal

export const OrdersCheckAllOrders = () => (dispatch) => {
	dispatch({ type: ORDERS_CHECK_ALL_ORDERS });
	UIkit.modal("#modalCheckallOrders").hide();
};

//Dispatch modal
export const HubOrdersCheckParcelArray = () => (dispatch, getState) => {
	const parcel_array = getState().orders_store.parcel_array;
	for (var i = 0; i < parcel_array.length; i++) {
		if (
			parcel_array[i].dimensions.length == "" ||
			parcel_array[i].dimensions.length == "0" ||
			parcel_array[i].dimensions.width == "" ||
			parcel_array[i].dimensions.width == "0" ||
			parcel_array[i].dimensions.height == "" ||
			parcel_array[i].dimensions.height == "0" ||
			parcel_array[i].dimensions.weight == "" ||
			parcel_array[i].dimensions.weight == "0"
		) {
			alertify.error("Length, Width, Height, Weight required for " + parcel_array[i].awb);
			return false;
		}
	}
	return true;
};

export const HubOrdersDispatchItems = () => (dispatch, getState) => {
	if (dispatch(HubOrdersCheckParcelArray()) == true) {
		const parcel_array = getState().orders_store.parcel_array;
		var weight_diff_awb = [];
		var over30awb = [];
		var weight_diff_flag = true;
		var courier_itemlist = getState().orders_store.datalist.courier_itemlist;
		parcel_array.map(function (parcel_item) {
			var item = courier_itemlist.filter((item) => item.awb == parcel_item.awb);
			var theoretical_weight = item.theoretical_weight;
			if (
				parseInt(parcel_item.dimensions.weight) > theoretical_weight * 1.15 ||
				parseInt(parcel_item.dimensions.weight) < theoretical_weight * 0.85
			) {
				weight_diff_awb.push(parcel_item.awb);
			}
			if (parseInt(parcel_item.dimensions.weight) > 30000) {
				over30awb.push(parcel_item.awb);
			}
		});
		if (weight_diff_awb.length > 0) {
			weight_diff_flag = confirm(
				"The weight of the following awbs seem to be too much different.\n" + weight_diff_awb.toString() + "\nDo you want to continue?"
			);
		}
		if (over30awb.length > 0) {
			alert("The weight of the following awbs is greater than 30 kg\n" + over30awb.toString());
		}
		if (weight_diff_flag == true && over30awb.length == 0) {
			return fetch(ProjectUrl + "/orders/dispatchitems", {
				method: "POST",
				credentials: "include",
				headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
				body: JSON.stringify({ dimensions: JSON.stringify(parcel_array), hub: true })
			})
				.then((res) => (res.ok ? res.json() : Promise.reject(res)))
				.then((data) => {
					dispatch({ type: ORDERS_RELOAD_DATA, data: data });
					UIkit.modal("#dispatchModal").hide();
				})
				.catch((error) => {
					if (error instanceof Error) alertify.error(error);
					else error.text().then((data) => alertify.error(data));
					return Promise.reject();
				});
		} else {
			return Promise.resolve();
		}
	} else {
		return Promise.resolve();
	}
};

export const HubOrdersSetDimension = (awb, dimension, value) => (dispatch) => {
	var val = value;
	if (dimension == "weight") {
		val = parseFloat(value) * 1000;
	}
	dispatch({ type: ORDERS_SET_PARCEL_DIMENSION, awb: awb, dimension: dimension, value: val });
};

export const HubOrdersDispatchPressEnterCheck = (char_code, value) => (dispatch, getState) => {
	if (char_code == 13) {
		var awb_no = value;
		var input_field = document.getElementById("awbno");
		if (awb_no == "") {
			alertify.error("AWB cannot be empty");
		} else {
			const awb_selected_items = getState().orders_store.awb_selected_items;
			const courier_itemlist = getState().orders_store.datalist.courier_itemlist;
			if (awb_selected_items.indexOf(awb_no) != -1) {
				alertify.error("AWB already exists");
			} else if (courier_itemlist.filter((item) => item.awb == awb_no).length == 0) {
				alertify.error("AWB not found");
			} else {
				dispatch({ type: ORDERS_SCAN_AWB_ITEM, data: awb_no });
			}
			input_field.value = "";
		}
	}
};

//Orders Dispatch Modal
const OrdersDispatchCheckParcelArray = () => (dispatch, getState) => {
	const parcel_array = getState().orders_store.parcel_array;
	for (var i = 0; i < parcel_array.length; i++) {
		if (parcel_array[i].dimensions.length == "") {
			parcel_array[i].dimensions.length = 0;
		}
		if (parcel_array[i].dimensions.width == "") {
			parcel_array[i].dimensions.width = 0;
		}
		if (parcel_array[i].dimensions.height == "") {
			parcel_array[i].dimensions.height = 0;
		}
		if (parcel_array[i].dimensions.weight == "" || parcel_array[i].dimensions.weight == "0") {
			alertify.error("Weight required for " + parcel_array[i].awb);
			return false;
		}
		if (parcel_array[i].dimensions.weight > 25000) {
			alertify.error("Weight should be below 25 KG for " + parcel_array[i].awb);
			return false;
		}
		if (parcel_array[i].dimensions.length > 150 || parcel_array[i].dimensions.width > 150 || parcel_array[i].dimensions.height > 150) {
			alertify.error("Length, width, height cannot be above 150 cms for " + parcel_array[i].awb);
			return false;
		}
	}
	return true;
};

export const OrdersDispatchItems = () => (dispatch, getState) => {
	if (dispatch(OrdersDispatchCheckParcelArray()) == true) {
		var parcel_array = getState().orders_store.parcel_array;
		var weight_diff_awb = [];
		var weight_diff_flag = true;
		const courier_itemlist = getState().orders_store.datalist.courier_itemlist;
		parcel_array.map((parcel_item) => {
			var item = courier_itemlist.filter((item) => item.awb == parcel_item.awb)[0];
			var theoretical_weight = item.theoretical_weight + 55;
			if (item.theoretical_weight < 100) {
				theoretical_weight = item.theoretical_weight + 65;
			} else if (item.theoretical_weight > 500) {
				theoretical_weight = item.theoretical_weight * 1.1 + 40;
			}
			if (
				item.lowest_weight > 0 &&
				(parseInt(parcel_item.dimensions.weight) > theoretical_weight * 1.15 ||
					parseInt(parcel_item.dimensions.weight) < theoretical_weight * 0.85)
			)
				weight_diff_awb.push(parcel_item.awb);
		});
		if (weight_diff_awb.length > 0) {
			weight_diff_flag = confirm(
				"The weight of the following awbs seem to be too much different.\n" + weight_diff_awb.toString() + "\nDo you want to continue?"
			);
		}
		if (weight_diff_flag == true && parcel_array.length > 0) {
			return fetch(ProjectUrl + "/orders/dispatchitems", {
				method: "POST",
				credentials: "include",
				headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
				body: JSON.stringify({ dimensions: JSON.stringify(parcel_array) })
			})
				.then((res) => (res.ok ? res.json() : Promise.reject(res)))
				.then((data) => {
					dispatch({ type: ORDERS_RELOAD_DATA, data: data });
					UIkit.modal("#dispatchModal").hide();
				})
				.catch((error) => {
					if (error instanceof Error) alertify.error(error);
					else error.text().then((data) => alertify.error(data));
					return Promise.reject();
				});
		} else {
			return Promise.resolve();
		}
	} else {
		return Promise.resolve();
	}
};

export const OrdersDispatchSetDimension = (awb, dimension, value) => (dispatch) => {
	dispatch({ type: ORDERS_SET_PARCEL_DIMENSION, awb: awb, dimension: dimension, value: value });
};

export const OrdersDispatchBoxSizeChange = (value) => (dispatch) => {
	dispatch({ type: ORDERS_CHANGE_BOX_SIZE, size: value });
};

export const OrdersDispatchSetBoxWeight = (awb_no) => (dispatch, getState) => {
	fetch("https://localhost:8765/weight/", {
		method: "POST"
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			if (parseInt(data) > 50) {
				dispatch({ type: ORDERS_SET_PARCEL_DIMENSION, awb: awb_no, dimension: "weight", value: data });
			}
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const OrdersDispatchPressEnterCheck = (char_code, value) => (dispatch, getState) => {
	if (char_code == 13) {
		var input_field = document.getElementById("awbno");
		var awb_no = value;
		const courier_itemlist = getState().orders_store.datalist.courier_itemlist;
		const awb_selected_items = getState().orders_store.awb_selected_items;
		if (awb_no == "") alertify.error("AWB cannot be empty");
		else {
			if (awb_selected_items.indexOf(awb_no) != -1) {
				alertify.error("AWB already exists");
			} else if (courier_itemlist.filter((item) => item.awb == awb_no).length == 0) alertify.error("AWB not found");
			else {
				dispatch({ type: ORDERS_SCAN_AWB_ITEM, data: awb_no });
				dispatch(OrdersDispatchSetBoxWeight(awb_no));
				input_field.value = "";
			}
			input_field.value = "";
		}
	}
};

export const OrdersDispatchFillDimensions = (awb_no) => (dispatch) => {
	dispatch({ type: ORDERS_SET_FILE_DIMENSION, data: awb_no });
};

//Print Slips
export const OrdersPrintSlips = (action) => (dispatch, getState) => {
	const selected_list = getState().orders_store.awb_selected_items;
	$(
		'<form method="post" action="/reports/' +
			action +
			'" target="_blank">' +
			'<input type="hidden" name="idlist" value="' +
			selected_list +
			'">' +
			"</form>"
	)
		.appendTo("body")
		.submit()
		.remove();
};
