import { batch } from "react-redux";

import {
	OPPORTUNITIES_RELOAD_DATA,
	OPPORTUNITIES_GET_INITIAL_DATA,
	OPPORTUNITIES_APPEND_DATA,
	COMMISSION_CHANGE_COMMISSION_AFTER_UPDATE,
	COMMISSION_CHANGE_ID_CODE_AND_COMMISSION,
	OPPORTUNITIES_CHANGE_CATEGORY,
	OPPORTUNITIES_CHANGE_SEARCH,
	OPPORTUNITIES_CLEAR_DATA,
	OPPORTUNITIES_CHANGE_SELLER,
	TOP_SELLING_RELOAD_SELLER_DATA,
	TOP_SELLING_RELOAD_DATA,
	TOP_SELLING_APPEND_DATA,
	OPPORTUNITIES_CLEAR_LOCAL_STATE,
	OPPORTUNITIES_CHANGE_PAGE_SORT
} from "~/actions/action-types";

import { ProjectUrl } from "~/env";

//Common
export const OpportunitiesGetInitialData = (type) => (dispatch) => {
	dispatch({ type: OPPORTUNITIES_CLEAR_LOCAL_STATE });
	const url =
		type == "stock_suggestion"
			? "/opportunities/getstocksuggestions"
			: type == "commission_suggestion"
			? "/opportunities/getcommissionsuggestions"
			: type == "new_product_suggestion"
			? "/opportunities/getnewproductsuggestions"
			: "/opportunities/gettopsellingproductsdata";
	return fetch(ProjectUrl + url, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: OPPORTUNITIES_GET_INITIAL_DATA, data: data });
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const OpportunitiesClearData = () => ({ type: OPPORTUNITIES_CLEAR_DATA });

//Commission Suggestion
const CommissionGetData = (page_size, page_no, category, search, seller, type) => (dispatch) => {
	nanobar.go(50);
	return fetch(ProjectUrl + "/opportunities/getpricingsuggestions", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ pagesize: page_size, pageno: page_no, category: category, search: search, seller: seller })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			if (type == OPPORTUNITIES_APPEND_DATA) {
				dispatch({ type: OPPORTUNITIES_APPEND_DATA, data: data });
			} else {
				dispatch({ type: OPPORTUNITIES_RELOAD_DATA, data: data, page_type: "" });
			}
			nanobar.go(100);
		})

		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const CommissionPageNoChange = () => (dispatch, getState) => {
	const page_size = getState().opportunities_store.page_size;
	const page_no = getState().opportunities_store.page_no;
	const category = getState().opportunities_store.category;
	const search = getState().opportunities_store.search;
	const seller = getState().opportunities_store.seller;
	return CommissionGetData(page_size, page_no + 1, category, search, seller, OPPORTUNITIES_APPEND_DATA)(dispatch);
};

export const CommissionEditIdAndCommission = (id_code) => (dispatch, getState) => {
	const reducer_id_code = getState().opportunities_store.id_code;
	if (id_code == reducer_id_code) {
		dispatch({ type: COMMISSION_CHANGE_ID_CODE_AND_COMMISSION, id_code: "", commission: "" });
	} else {
		dispatch({ type: COMMISSION_CHANGE_ID_CODE_AND_COMMISSION, id_code: id_code, commission: "" });
	}
};

export const CommissionChangeIdCode = (id_code, value) => (dispatch) => {
	dispatch({ type: COMMISSION_CHANGE_ID_CODE_AND_COMMISSION, id_code: id_code, commission: value });
};

export const CommissionSave = () => (dispatch, getState) => {
	const commission = getState().opportunities_store.commission;
	const id_code = getState().opportunities_store.id_code;
	if (commission == "") {
		alertify.error("Commission cannot be empty");
	} else {
		const dataset = {
			idcode: id_code,
			commission: parseInt(commission)
		};
		fetch(ProjectUrl + "/opportunities/updateitemcommission", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify(dataset)
		})
			.then((res) => (res.ok ? res.json() : Promise.reject(res)))
			.then((data) => {
				if (data.status_code != 200) {
					alertify.error(data.message);
				} else {
					alertify.success("Commission Saved");
					dispatch({ type: COMMISSION_CHANGE_ID_CODE_AND_COMMISSION, id_code: "", commission: "" });
					dispatch({ type: COMMISSION_CHANGE_COMMISSION_AFTER_UPDATE, data: dataset });
				}
			})
			.catch((error) => {
				if (error instanceof Error) alertify.error(error);
				else error.text().then((data) => alertify.error(data));
			});
	}
};

export const CommissionFilterCategory = (name, value) => (dispatch, getState) => {
	const level1 = getState().opportunities_store.level1;
	const level2 = getState().opportunities_store.level2;
	const level3 = getState().opportunities_store.level3;
	if (name == "level2" && value == "") value = level1;
	if (name == "level3" && value == "") value = level2;
	if (name == "level4" && value == "") value = level3;
	dispatch({ type: OPPORTUNITIES_CHANGE_CATEGORY, name: name, data: value });
	const page_size = getState().opportunities_store.page_size;
	const search = getState().opportunities_store.search;
	const seller = getState().opportunities_store.seller;
	dispatch(CommissionGetData(page_size, 1, value, search, seller));
};

export const CommissionPressEnterCheck = (e) => (dispatch, getState) => {
	if (e.charCode == 13 || e.type == "click") {
		dispatch({ type: OPPORTUNITIES_CHANGE_SEARCH, data: e.target.value });
		const page_size = getState().opportunities_store.page_size;
		const category = getState().opportunities_store.category;
		const seller = getState().opportunities_store.seller;
		const search = getState().opportunities_store.search;
		dispatch(CommissionGetData(page_size, 1, category, search, seller));
	}
};

export const CommissionFilterSeller = (value) => (dispatch, getState) => {
	dispatch({ type: OPPORTUNITIES_CHANGE_SELLER, data: value });
	const page_size = getState().opportunities_store.page_size;
	const category = getState().opportunities_store.category;
	const search = getState().opportunities_store.search;
	dispatch(CommissionGetData(page_size, 1, category, search, value));
};

//New Product Suggestion
const NewProductGetData = (page_size, page_no, category, seller, type) => (dispatch) => {
	nanobar.go(50);
	return fetch(ProjectUrl + "/opportunities/getnewproductsopportunities", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ pagesize: page_size, pageno: page_no, category: category, seller: seller })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			if (type == OPPORTUNITIES_APPEND_DATA) {
				dispatch({ type: OPPORTUNITIES_APPEND_DATA, data: data });
			} else {
				dispatch({ type: OPPORTUNITIES_RELOAD_DATA, data: data, page_type: "" });
			}
			nanobar.go(100);
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const NewProductFilterCategory = (name, value) => (dispatch, getState) => {
	const level1 = getState().opportunities_store.level1;
	const level2 = getState().opportunities_store.level2;
	const level3 = getState().opportunities_store.level3;

	if (name == "level2" && value == "") value = level1;
	if (name == "level3" && value == "") value = level2;
	if (name == "level4" && value == "") value = level3;
	dispatch({ type: OPPORTUNITIES_CHANGE_CATEGORY, name: name, data: value });

	const page_size = getState().opportunities_store.page_size;
	const seller = getState().opportunities_store.seller;
	dispatch(NewProductGetData(page_size, 1, value, seller));
};

export const NewProductFilterSeller = (value) => (dispatch, getState) => {
	dispatch({ type: OPPORTUNITIES_CHANGE_SELLER, data: value });
	const page_size = getState().opportunities_store.page_size;
	const category = getState().opportunities_store.category;
	dispatch(NewProductGetData(page_size, 1, category, value));
};

export const NewProductPageNoChange = () => (dispatch, getState) => {
	const page_size = getState().opportunities_store.page_size;
	const page_no = getState().opportunities_store.page_no;
	const category = getState().opportunities_store.category;
	const seller = getState().opportunities_store.seller;
	return NewProductGetData(page_size, page_no + 1, category, seller, OPPORTUNITIES_APPEND_DATA)(dispatch);
};

//Stock Suggestion
const StockGetData = (page_size, page_no, page_type, category, page_change, seller, type) => (dispatch) => {
	nanobar.go(50);
	return fetch(ProjectUrl + "/opportunities/getproductopportunities", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ pagesize: page_size, pageno: page_no, pagetype: page_type, category: category, seller: seller })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			if (type == OPPORTUNITIES_APPEND_DATA) {
				dispatch({ type: OPPORTUNITIES_APPEND_DATA, data: data });
			} else {
				dispatch({ type: OPPORTUNITIES_RELOAD_DATA, data: data, page_type: page_type, page_change: page_change });
			}
			nanobar.go(100);
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const StockPageTypeChange = (page_type) => (dispatch, getState) => {
	const page_size = getState().opportunities_store.page_size;
	const seller = getState().opportunities_store.seller;
	dispatch(StockGetData(page_size, 1, page_type, "", true, seller));
};

export const StockPageNoChange = () => (dispatch, getState) => {
	const page_size = getState().opportunities_store.page_size;
	const page_no = getState().opportunities_store.page_no;
	const page_type = getState().opportunities_store.page_type;
	const category = getState().opportunities_store.category;
	const seller = getState().opportunities_store.seller;
	return StockGetData(page_size, page_no + 1, page_type, category, false, seller, OPPORTUNITIES_APPEND_DATA)(dispatch);
};

export const StockFilterCategory = (name, value) => (dispatch, getState) => {
	const level1 = getState().opportunities_store.level1;
	const level2 = getState().opportunities_store.level2;
	const level3 = getState().opportunities_store.level3;

	if (name == "level2" && value == "") value = level1;
	if (name == "level3" && value == "") value = level2;
	if (name == "level4" && value == "") value = level3;

	dispatch({ type: OPPORTUNITIES_CHANGE_CATEGORY, name: name, data: value });

	const page_size = getState().opportunities_store.page_size;
	const seller = getState().opportunities_store.seller;
	const page_type = getState().opportunities_store.page_type;
	dispatch(StockGetData(page_size, 1, page_type, value, false, seller));
};

export const StockFilterSeller = (value) => (dispatch, getState) => {
	dispatch({ type: OPPORTUNITIES_CHANGE_SELLER, data: value });
	const page_size = getState().opportunities_store.page_size;
	const category = getState().opportunities_store.category;
	const page_type = getState().opportunities_store.page_type;

	dispatch(StockGetData(page_size, 1, page_type, category, false, value));
};

//Top Selling Products
const TopSellingGetData = (page_size, page_no, category, group_by, sort_by, seller, type) => (dispatch) => {
	nanobar.go(50);
	return fetch(ProjectUrl + "/opportunities/gettopsellingproducts", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ pagesize: page_size, pageno: page_no, category: category, groupby: group_by, sortby: sort_by, seller: seller })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			if (type == "Append") {
				dispatch({ type: TOP_SELLING_APPEND_DATA, data: data });
			} else {
				dispatch({ type: TOP_SELLING_RELOAD_DATA, data: data, group_by: group_by });
			}

			nanobar.go(100);
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};

export const TopSellingPageSortChange = (sort_by) => (dispatch, getState) => {
	dispatch({ type: OPPORTUNITIES_CHANGE_PAGE_SORT, data: sort_by });
	const page_size = getState().opportunities_store.page_size;
	const category = getState().opportunities_store.category;
	const group_by = getState().opportunities_store.group_by;
	const seller = getState().opportunities_store.seller;
	dispatch(TopSellingGetData(page_size, 1, category, group_by, sort_by, seller));
};

export const TopSellingPageNoChange = () => (dispatch, getState) => {
	const page_size = getState().opportunities_store.page_size;
	const page_no = getState().opportunities_store.page_no;
	const group_by = getState().opportunities_store.group_by;
	const category = getState().opportunities_store.category;
	const sort_by = getState().opportunities_store.sort_by;
	const seller = getState().opportunities_store.seller;
	return TopSellingGetData(page_size, page_no + 1, category, group_by, sort_by, seller, "Append")(dispatch);
};

export const TopSellingGroupByChange = (e) => (dispatch, getState) => {
	const page_size = getState().opportunities_store.page_size;
	const category = getState().opportunities_store.category;
	const sort_by = getState().opportunities_store.sort_by;
	const seller = getState().opportunities_store.seller;
	dispatch(TopSellingGetData(page_size, 1, category, e.target.value, sort_by, seller));
};

export const TopSellingFilterCategory = (name, e) => (dispatch, getState) => {
	dispatch({ type: OPPORTUNITIES_CHANGE_CATEGORY, name: name, data: e.target.value });
	const page_size = getState().opportunities_store.page_size;
	const sort_by = getState().opportunities_store.sort_by;
	const group_by = getState().opportunities_store.group_by;
	const seller = getState().opportunities_store.seller;
	dispatch(TopSellingGetData(page_size, 1, e.target.value, group_by, sort_by, seller));
};

export const TopSellingFilterSeller = (e) => (dispatch, getState) => {
	const page_size = getState().opportunities_store.page_size;
	const page_no = getState().opportunities_store.page_no;
	const group_by = getState().opportunities_store.group_by;
	const category = getState().opportunities_store.category;
	const sort_by = getState().opportunities_store.sort_by;
	const seller = e.target.value;
	nanobar.go(50);
	fetch(ProjectUrl + "/opportunities/gettopsellingproductssellerwise", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({
			pagesize: page_size,
			pageno: page_no,
			category: category,
			groupby: group_by,
			sortby: sort_by,
			seller: seller
		})
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: TOP_SELLING_RELOAD_SELLER_DATA, data: data, seller: seller });
			nanobar.go(100);
		})
		.catch((error) => {
			nanobar.go(100);
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
		});
};
