import { memo } from "react";

const Modal = memo(({ fullScreen, large, medium, small, micro, className, children, closeButton, closeButtonOutside, onCloseButtonClick, id, bgClose = true, escClose = true }) => {
    let modalWrapClass = "";
    let modalClass = "uk-modal-dialog uk-margin-auto-vertical";

    if (fullScreen) {
        modalWrapClass += " uk-modal-full";
    }

    if (large) {
        modalWrapClass += " uk-modal-container";
    }

    if (medium) {
        modalClass += " uk-width-2-3@s";
    }

    if (small) {
        modalClass += " uk-width-2-5@s";
    }

    if (micro) {
        modalClass += " uk-width-1-4@s";
    }

    if (className) {
        modalClass += " ".concat(className);
    }

    return (
        <div id={id} className={modalWrapClass} bg-close={bgClose.toString()} esc-close={escClose.toString()} container="false" uk-modal="">
            <div className={modalClass}>
                {closeButton || closeButtonOutside ? (
                    <button
                        className={fullScreen ? "uk-modal-close-full uk-close-large" : closeButtonOutside ? "uk-modal-close-outside" : "uk-modal-close-default"}
                        type="button"
                        uk-close=""
                        onClick={onCloseButtonClick}
                    ></button>
                ) : null}
                {children}
            </div>
        </div>
    );
});

export default Modal;