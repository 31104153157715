import { connect } from "react-redux";

import Modal from "~/components/common/modal";
import { Button } from "@bit/healthmug.admin.button";

import { useLoader } from "~/actions/custom-hooks";
import { OrdersUpdateMrp } from "~/actions/orders-action";

const UpdateMrpModal = (props) => {
	const [button_loader, start_button_loader, end_button_loader] = useLoader(false);

	const Orders_Update_Mrp = (cancel_reason) => {
		start_button_loader();
		props.Orders_Update_Mrp(cancel_reason).finally(end_button_loader);
	};
	return (
		<Modal micro closeButtonOutside bgClose={false} escClose={false} id="modalMrpUpdate">
			<div className="modalBody">
				<div className="mediumFont uk-margin-small-bottom">Do you really want to update the MRP?</div>
				<Button loader={button_loader} disabled={button_loader} onClick={() => Orders_Update_Mrp(true)} primary block small className="uk-margin-small-top uk-width-1-1">
					Yes
				</Button>
				<Button disabled={button_loader} onClick={() => Orders_Update_Mrp(false)} block small className="uk-modal-close uk-margin-small-top uk-width-1-1">
					Cancel
				</Button>
			</div>
		</Modal>
	);
};

const mapDispatchToProps = (dispatch) => ({
	Orders_Update_Mrp: (mrp_update_confirmation) => dispatch(OrdersUpdateMrp(mrp_update_confirmation))
});
export default connect(null, mapDispatchToProps)(UpdateMrpModal);